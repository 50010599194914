import { Typography, Table, Alert } from "antd";
import styles from "./OffPlatform.module.scss";
import { useFeedContext } from "../../shared/contexts/FeedContext";
import { useOffPlatformExportContext } from "./OffPlatformExportContext";
import { RowType } from "../shared/types";
import { getColumns } from "./MapDataColumns";
import { getMapDataTableData } from "./utils/getMapDataTableData";
import { HandleSelectPortalOptions, PortalOptionMatchTableData } from "./types";
import { getUpdatedMatches } from "./utils/getUpdatedMatches";

export const MapData = () => {
  const { columns: feedColumns } = useFeedContext();
  const { portalOptionMatches, updatePortalOptionMatches } =
    useOffPlatformExportContext();

  const tableData = getMapDataTableData(feedColumns);

  const handleSelectPortalOption: HandleSelectPortalOptions = (
    portalOption,
    rowData,
  ) => {
    updatePortalOptionMatches(previousMatches =>
      getUpdatedMatches(previousMatches, portalOption, rowData),
    );
  };

  const columns = getColumns(portalOptionMatches, handleSelectPortalOption);

  const getTableRowClassName = (record: PortalOptionMatchTableData) => {
    const isMediaRow = record.rowType === RowType.Media;
    const hasMapping =
      portalOptionMatches.tags?.includes(record.key) ||
      portalOptionMatches.metas?.includes(record.key) ||
      portalOptionMatches.notInclude?.includes(record.key);

    if (!hasMapping && !isMediaRow) {
      return styles.highlightRow;
    }
    return "";
  };

  return (
    <div className={styles.mapDataWrapper}>
      <div className={styles.alertContainer}>
        <Typography.Text>
          Please set how the assets will appear in the portal using the
          selectboxes on the right.
        </Typography.Text>
        <Alert
          message="Content from unmapped columns will be added to the spreadsheet, but won't show in the portal."
          type="warning"
          showIcon
        />
      </div>
      <Table
        size="small"
        columns={columns}
        dataSource={tableData}
        pagination={false}
        bordered
        rowClassName={getTableRowClassName}
      />
    </div>
  );
};

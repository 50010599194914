import API from "services";
import { IFilter } from "shared/hooks/assetExporter/useFeedConfigFetch";
import { useFeedContext } from "../contexts/FeedContext";
import { useQuery } from "react-query";
import { errorNotification } from "shared/components/customNotification/Notification";

interface IGetAssetBatchIdsForFilteredRowsArgs {
  feedId: string;
  reqSearchValue: string;
  filterArgs: IFilter[];
}

const getAssetBatchIdsForFilteredRows = async ({
  feedId,
  reqSearchValue,
  filterArgs,
}: IGetAssetBatchIdsForFilteredRowsArgs) => {
  try {
    const { result } =
      await API.services.assetExporter.getAssetBatchIdsForFilteredRows({
        feedId,
        search: reqSearchValue,
        filters: filterArgs,
      });

    if (!result || !result.assetBatchIdsForFilteredRows) {
      throw new Error("Unable to get feed asset batch ids for filtered rows");
    }

    return result;
  } catch (error) {
    errorNotification({
      messageLabel: "An error occurred while fetching feed data",
    });
    throw error;
  }
};

export const useGetAssetBatchIdsForFilteredRows = (
  search: string,
  filters: IFilter[],
) => {
  const { feedId } = useFeedContext();

  return useQuery(
    ["assetBatchIdsForFilteredRows", feedId, search, JSON.stringify(filters)],
    () =>
      getAssetBatchIdsForFilteredRows({
        feedId,
        reqSearchValue: search,
        filterArgs: filters,
      }),
    {
      enabled: !!feedId,
      cacheTime: 0,
    },
  );
};

import React, { useContext, useState } from "react";
import { MappingDataForExportPortal } from "shared/types/assetExporter";
import { useFeedContext } from "../../shared/contexts/FeedContext";

type UpdatePortalOptionMatches = (
  updateFn?: (
    previousMatches: MappingDataForExportPortal,
  ) => MappingDataForExportPortal | undefined,
) => void;

interface OffPlatformExportContextType {
  portalOptionMatches: MappingDataForExportPortal;
  updatePortalOptionMatches: UpdatePortalOptionMatches;
}

const OffPlatformExportContext = React.createContext<
  OffPlatformExportContextType | undefined
>(undefined);

const emptyPortalOptionMatches = {
  tags: [],
  metas: [],
  notInclude: [],
};

export const OffPlatformExportProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { googleSpreadsheetMappings } = useFeedContext();
  const defaultPortalOptionMatches =
    googleSpreadsheetMappings ?? emptyPortalOptionMatches;

  const [portalOptionMatches, setPortalOptionMatches] =
    useState<MappingDataForExportPortal>(defaultPortalOptionMatches);

  const updatePortalOptionMatches: UpdatePortalOptionMatches = updateFn => {
    setPortalOptionMatches(previousMatches => {
      return updateFn?.(previousMatches) ?? defaultPortalOptionMatches;
    });
  };

  const contextValue: OffPlatformExportContextType = {
    portalOptionMatches,
    updatePortalOptionMatches,
  };

  return (
    <OffPlatformExportContext.Provider value={contextValue}>
      {children}
    </OffPlatformExportContext.Provider>
  );
};

OffPlatformExportProvider.displayName = "OffPlatformExportProvider";

export const useOffPlatformExportContext = () => {
  const offPlatformExportContext = useContext(OffPlatformExportContext);
  if (!offPlatformExportContext) {
    throw new Error(
      "useOffPlatformExportContext must be used within an OffPlatformExportProvider",
    );
  }
  return offPlatformExportContext;
};

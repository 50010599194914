import { Pagination } from "antd";
import { useDataList } from "shared/components/dataList/useDataList";
import { FeedTblRow } from "shared/types/assetExporter";
import styles from "./FeedPagination.module.scss";

const PAGE_SIZE = 30;

const getDisplayingStr = (
  currentPage: number,
  totalRows: number,
  filteredTotalCount: number,
) => {
  const rowStartIndex = !totalRows ? 0 : PAGE_SIZE * (currentPage - 1) + 1;
  const rowEndIndex = !totalRows ? 0 : rowStartIndex + totalRows - 1;
  return `displaying ${rowStartIndex} to ${rowEndIndex} of ${filteredTotalCount} items`;
};

export const FeedPagination = ({
  totalRows,
  filteredTotalCount,
  currentPage,
  setCurrentPage,
  setShowSelectAllOption,
}: {
  totalRows: number;
  filteredTotalCount: number;
  currentPage: number;
  setCurrentPage: (pageNumber: number) => void;
  setShowSelectAllOption: (value: boolean) => void;
}) => {
  const [_, dataListActions] = useDataList<FeedTblRow>();

  return (
    <div className={styles.paginationContainer}>
      <span className={styles.displayingDescContainer}>
        {getDisplayingStr(currentPage, totalRows, filteredTotalCount)}
      </span>
      <Pagination
        current={currentPage}
        pageSize={PAGE_SIZE}
        showSizeChanger={false}
        total={filteredTotalCount}
        onChange={(pageNumber: number) => {
          dataListActions.setSelectedItems([]);
          dataListActions.setSelectAllItems(false);
          setShowSelectAllOption(false);
          setCurrentPage(pageNumber);
        }}
      />
    </div>
  );
};

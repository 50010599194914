import API from "services";
import HttpClient from "services/httpClient";
import {
  GoogleDriveMetadataResponse,
  MediaColErrorArgs,
} from "shared/types/assetExporter";

export const validMediaExtensions = ["jpg", "jpeg", "png", "mp4"];

export const urlHasExtension = (url: string, extensions: string[]) => {
  // Checks for a URL ending with a valid media extension
  const regex = new RegExp(
    `^http[^\\?]*.(${extensions.join("|")})(\\?(.*))?$`,
    "gim",
  );
  return url.match(regex) ? true : false;
};

export const convertDropboxUrl = (url: string) => {
  return url.includes("www.dropbox.com")
    ? url.replace("?dl=0", "").replace("www.", "dl.")
    : url;
};

export const extractExtension = (url: string) => {
  const parts = url.split(".");
  const extStr = parts[parts.length - 1];
  const extWithFirstFilter =
    extStr?.indexOf("?") === -1
      ? extStr
      : extStr.substring(0, extStr?.indexOf("?"));
  const ext = extWithFirstFilter?.split("&")[0];

  return ext;
};

export const isValidMediaExtension = (extension: string) => {
  return validMediaExtensions.includes(extension);
};

export const getMediaColumnError = ({
  backgroundColumn,
  validRowsForCol,
  totalRows,
}: MediaColErrorArgs) => {
  return validRowsForCol !== undefined && totalRows !== validRowsForCol
    ? `${validRowsForCol} of ${totalRows} rows contain valid media urls for ${backgroundColumn}`
    : undefined;
};

export const isGDriveUrl = (url?: string) => {
  return url?.includes("drive.google.com") ?? false;
};

export const getGoogleDriveFileExtension = async (url: string) => {
  const { url: gdriveUrl, accessToken } =
    await API.privServices.assetExporter.getGoogleDriveAPIUrl(url, "metadata");
  const metadata = await HttpClient.get<GoogleDriveMetadataResponse>(
    gdriveUrl,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
    false,
  );
  return metadata?.mimeType?.split("/")[1];
};

export const getGoogleDriveFileUrl = async (url: string) => {
  const { url: gdriveUrl, accessToken } =
    await API.privServices.assetExporter.getGoogleDriveAPIUrl(url, "file");
  const response = await fetch(gdriveUrl, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
  }
  const blob = await response.blob();
  return URL.createObjectURL(blob);
};

export const checkIfItIsVideoSrc = async (rawSrc: string) => {
  if (!rawSrc) return { isVideo: false, isGdriveSrc: false, src: "" };
  const isGdriveSrc = isGDriveUrl(rawSrc);
  const gDriveExt = isGdriveSrc
    ? await getGoogleDriveFileExtension(rawSrc)
    : "";
  const src = isGdriveSrc
    ? await getGoogleDriveFileUrl(rawSrc)
    : convertDropboxUrl(rawSrc);

  const isVideo = isGdriveSrc
    ? gDriveExt.includes("mp4")
    : rawSrc.includes(".mp4");

  return { isVideo, isGdriveSrc, src };
};

import { useQuery } from "react-query";
import API from "services";
import { getResultIfOk } from "services/adLibrary";
import { QUERY_KEYS } from "screens/designStudio/printOptions/constants";
import { PrintOptionItem } from "shared/types/printOptions";

export const useFetchPrintOptions = () => {
  return useQuery<PrintOptionItem[] | undefined, Error>({
    queryKey: [QUERY_KEYS.printOptions],
    queryFn: async () => {
      const { result } = getResultIfOk(
        await API.services.salesEnablement.getPrintOptions(),
      );
      return result;
    },
  });
};

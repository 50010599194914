import { useEffect, useRef, useState } from "react";

export const useIsScrolledToBottom = () => {
  const ref = useRef<HTMLDivElement>(null);
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false);

  useEffect(() => {
    const checkScroll = () => {
      const element = ref.current;
      if (element) {
        const hasScrollableContent =
          element.scrollHeight > element.clientHeight;
        const isScrolledToBottom =
          Math.abs(
            element.scrollHeight - element.clientHeight - element.scrollTop,
          ) < 1;
        setIsScrolledToBottom(!hasScrollableContent || isScrolledToBottom);
      }
    };

    const element = ref.current;
    if (element) {
      element.addEventListener("scroll", checkScroll);
      window.addEventListener("resize", checkScroll);
      checkScroll();
    }

    return () => {
      if (element) {
        element.removeEventListener("scroll", checkScroll);
        window.removeEventListener("resize", checkScroll);
      }
    };
  }, []);

  return { isScrolledToBottom, ref };
};

import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { ROUTES } from "screens/designStudio/archive/constants";
import { PreviewTemplate } from "shared/components/salesEnablement/template/PreviewTemplate";
import { useFetchArchives } from "shared/hooks/salesEnablement/useFetchArchives";
import useNavigateWithSearch from "shared/hooks/useNavigateWithSearch";

const ArchiveTemplateFileDrawer = () => {
  const { data: archives } = useFetchArchives();
  const { archiveId } = useParams<{ archiveId: string }>();

  const navigate = useNavigateWithSearch();

  const selectedArchive = useMemo(
    () => archives?.find(archive => archive.id === archiveId),
    [archives, archiveId],
  );

  const onClose = () => {
    navigate("..");
  };

  const onCreateMarketingMaterial = () => {
    if (selectedArchive?.templateData.id) {
      navigate(ROUTES.newMaterial(selectedArchive.templateData.id));
    }
  };

  return (
    <PreviewTemplate
      visible={!!selectedArchive}
      onClose={() => onClose()}
      templateData={selectedArchive?.templateData}
      onCreateMarketingMaterial={onCreateMarketingMaterial}
    />
  );
};

export default ArchiveTemplateFileDrawer;

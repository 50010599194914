import { Drawer, Typography, Tag, message } from "antd";
import {
  FileZipOutlined,
  FilePdfOutlined,
  FileExcelOutlined,
} from "@ant-design/icons";
import styles from "./EmailDrawer.module.scss";
import EmailDrawerFooter from "./EmailDrawerFooter";
import { Invoice } from "shared/types/salesEnablement";
import { useInvoice } from "../hooks/useInvoice";
import { getEnvVar } from "utils/helpers";

const EmailDrawer = ({
  data,
  isEmailDrawerVisible,
  closeEmailDrawer,
}: {
  data: Invoice[];
  isEmailDrawerVisible: boolean;
  closeEmailDrawer: () => void;
}) => {
  const { useEmailInvoice } = useInvoice();
  const { mutate: emailInvoice, isLoading } = useEmailInvoice();

  const handleSendEmail = async () => {
    if (!data || data.length === 0) {
      message.error("No invoices available to send");
      return;
    }

    const invoiceIds = data.map(item => item.id);

    emailInvoice(invoiceIds, {
      onSuccess: () => {
        message.success("Email sent successfully!");
        closeEmailDrawer();
      },
      onError: (error: any) => {
        message.error(
          `Failed to send email: ${error.message || "Unknown error"}`,
        );
      },
    });
  };
  return (
    <Drawer
      title={<b>Email</b>}
      width={528}
      visible={isEmailDrawerVisible}
      onClose={closeEmailDrawer}
      closable={false}
      destroyOnClose
      footer={
        <EmailDrawerFooter
          onClose={closeEmailDrawer}
          sendEmail={handleSendEmail}
          isLoading={isLoading}
        />
      }
    >
      <div className={styles.emailContainer}>
        <div className={styles.destinationEmail}>
          <div>
            <Typography.Text strong>Destination Email:</Typography.Text>
          </div>
          {JSON.parse(getEnvVar("INVOICE_EMAIL_IDS")).map((email: string) => (
            <div key={email}>
              <Tag>{email}</Tag>
            </div>
          ))}
        </div>
        {data.map(item => (
          <div key={item.id}>
            <div style={{ marginBottom: 16, marginTop: 16 }}>
              <Typography.Text strong>File(s) being sent:</Typography.Text>
            </div>
            <div className={styles.zipFile}>
              <FileZipOutlined />
              <Typography.Text strong> Invoice-{item.id}.zip</Typography.Text>
            </div>
            <div style={{ paddingLeft: 16 }} className={styles.pdfFile}>
              <FilePdfOutlined />
              <Typography.Text> Invoice-{item.id}.pdf</Typography.Text>
            </div>
            <div style={{ paddingLeft: 16 }} className={styles.csvFile}>
              <FileExcelOutlined />
              <Typography.Text> Invoice-{item.id}.csv</Typography.Text>
            </div>
            <div style={{ paddingLeft: 16 }} className={styles.csvFile}>
              <FileExcelOutlined />
              <Typography.Text>
                Communicorp_Order_Detail-{item.id}.csv
              </Typography.Text>
            </div>
          </div>
        ))}
      </div>
    </Drawer>
  );
};

export default EmailDrawer;

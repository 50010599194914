import styles from "./Portal.module.scss";
import { useWindowSize } from "shared/hooks/useWindowSize";
import { RightPanel } from "./RightPanel";
import { LeftPanel } from "./LeftPanel";

const IS_RIGHT_PANEL_ENABLED = false;

export const PortalConfiguration = () => {
  const { windowInnerHeight } = useWindowSize();

  return (
    <div className={styles.portalConfigurationWrapper}>
      <div
        className={styles.portalConfiguration}
        style={{ height: windowInnerHeight - 145 }}
      >
        <LeftPanel />
        {IS_RIGHT_PANEL_ENABLED && <RightPanel />}
      </div>
    </div>
  );
};

import DataListURLTable from "shared/components/dataListURL/DataListURLTable";
import { useDataListURLData } from "shared/components/dataListURL/useDataListURLData";
import { useWindowSize } from "shared/hooks/useWindowSize";
import {
  PrintOptionsCopy,
  PrintOptionsTableColumns,
  NestedPrintOptionItem,
  PrintOptionCopyType,
  PrintOptionsCopyCost,
} from "shared/types/printOptions";
import { getColumns } from "./columns";
import styles from "./NestedCopiesTable.module.scss";
import { useMemo } from "react";

export const NestedCopiesTable = ({
  costPerCopy,
}: {
  costPerCopy: PrintOptionsCopy<PrintOptionCopyType>[];
}) => {
  const { windowInnerHeight, windowInnerWidth } = useWindowSize();

  const normalizedData: NestedPrintOptionItem[] = useMemo(
    () =>
      costPerCopy
        .flatMap(printOptionCopy =>
          Object.entries(printOptionCopy.cost).map(([mode, value]) => {
            return {
              id: printOptionCopy.id + mode,
              // This assertion is necessary because Object.entries returns the key as string.
              printMode: [mode as keyof PrintOptionsCopyCost],
              costPerCopy: [
                { quantity: printOptionCopy.quantity, cost: value },
              ],
            };
          }),
        )
        .sort((a, b) => {
          const modeOrder = { SIMPLEX: 0, DUPLEX: 1 };
          return (
            modeOrder[a.printMode[0]?.toUpperCase() as keyof typeof modeOrder] -
            modeOrder[b.printMode[0]?.toUpperCase() as keyof typeof modeOrder]
          );
        }),
    [costPerCopy],
  );

  const tableData = useDataListURLData<
    PrintOptionsTableColumns,
    NestedPrintOptionItem
  >({
    data: normalizedData,
    isLoading: false,
    isError: false,
  });

  return (
    <DataListURLTable<PrintOptionsTableColumns, NestedPrintOptionItem>
      type="normal"
      fieldKeys={["copyQuantity", "copyCost"]}
      dataSource={tableData.data}
      className={styles.table}
      rowClassName={styles.row}
      columns={getColumns<NestedPrintOptionItem>()}
      scroll={{
        y: windowInnerHeight - 245,
        x: windowInnerWidth - 20,
      }}
      disableRowSelection
      showHeader={false}
      pagination={false}
      size="small"
    />
  );
};

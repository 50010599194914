import API from "services";
import { useMutation } from "react-query";
import { queryClient } from "queryClient";
import { QUERY_KEYS } from "screens/designStudio/printOptions/constants";
import { errorNotification } from "shared/components/customNotification/Notification";
import { PrintOptionItem } from "shared/types/printOptions";

export const useCreatePrintOption = ({
  onSuccess,
}: {
  onSuccess: () => void;
}) => {
  return useMutation({
    mutationFn: API.services.salesEnablement.createPrintOption,
    onMutate: async newPrintOption => {
      await queryClient.cancelQueries(QUERY_KEYS.printOptions);

      const previousPrintOptions = queryClient.getQueryData<PrintOptionItem[]>(
        QUERY_KEYS.printOptions,
      );

      if (!previousPrintOptions) {
        return;
      }

      const newPrintOptions = [...previousPrintOptions, newPrintOption];

      queryClient.setQueryData(QUERY_KEYS.printOptions, newPrintOptions);
      return previousPrintOptions;
    },
    onSettled: () => {
      queryClient.invalidateQueries([QUERY_KEYS.printOptions]);
    },
    onSuccess,
    onError: () => {
      errorNotification({
        messageLabel: "Something went wrong creating the print option.",
      });
    },
  });
};

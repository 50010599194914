import { Select, Typography } from "antd";
import { differenceBy } from "lodash";
import { queryClient } from "queryClient";
import { useState } from "react";
import { QUERY_KEYS } from "screens/designStudio/printOptions/constants";
import { PrintOptionsDrawer } from "screens/designStudio/printOptions/PrintOptionsDrawer";
import { PrintOptionRateTable } from "shared/components/salesEnablement/printOption/PrintOptionRateTable";
import CreateNewSelectLabel from "shared/components/select/CreateNewSelectLabel";
import { useFetchPrintOptions } from "shared/hooks/salesEnablement/useFetchPrintOptions";
import { PrintOptionItem } from "shared/types/printOptions";

export const PrintOptionsField = ({
  value: printOptionId,
  onChange,
}: {
  value?: string;
  onChange?: (fields: any) => void;
}) => {
  const [isAddingPrintOption, setIsAddingPrintOption] = useState(false);
  const { data: printOptions } = useFetchPrintOptions();
  const selectedPrintOption = printOptions?.find(
    option => option.id === printOptionId,
  );

  const onClickAddPrintOption = () => {
    setIsAddingPrintOption(true);
  };

  const onClose = async () => {
    setIsAddingPrintOption(false);
    const newPrintOptions = queryClient.getQueryData<PrintOptionItem[]>(
      QUERY_KEYS.printOptions,
    );
    const newOption = differenceBy(
      newPrintOptions ?? [],
      printOptions ?? [],
      "id",
    )[0];
    onChange?.(newOption?.id);
  };

  const createNewOption = {
    value: "",
    label: (
      <CreateNewSelectLabel
        onCreateClick={onClickAddPrintOption}
        label="Add Print Option"
      />
    ),
    labelEdit: (
      <Typography.Text type="secondary">Please Select</Typography.Text>
    ),
  };

  const options = [
    createNewOption,
    ...(printOptions?.map(option => ({
      value: option.id,
      label: option.name,
    })) ?? []),
  ];

  return (
    <>
      <Select value={printOptionId} onChange={onChange} options={options} />
      {selectedPrintOption && (
        <PrintOptionRateTable
          name="Price per copy"
          printOption={selectedPrintOption}
        />
      )}
      <PrintOptionsDrawer isVisible={isAddingPrintOption} onClose={onClose} />
    </>
  );
};

import { useState } from "react";
import {
  ToolbarButton,
  ToolbarTable,
} from "shared/components/toolbarTable/ToolbarTable";
import InvoiceFilterDrawer from "../InvoiceDrawer/FilterDrawer";
import { Invoice } from "shared/types/salesEnablement";
import { useDataListURLGlobalFilter } from "shared/components/dataListURL/useDataListURLGlobalFilter";
import { generateInvoiceColumns } from "./Columns";
import { TableActionsContextProvider } from "shared/components/HoverActionsVirtualTable/TableActionsContext";
import { ContextTableActions } from "shared/components/tableActions/ContextTableActions";
import { InlineTableActions } from "shared/components/tableActions/InlineTableActions";
import DataListURLTable from "shared/components/dataListURL/DataListURLTable";
import EmptyInvoice from "../EmptyInvoice/EmptyInvoice";
import { fields } from "../fields";
import { useWindowSize } from "shared/hooks/useWindowSize";
import { DataListTagsURL } from "shared/components/dataListURL/DataListTagsURL";
import styles from "./Table.module.scss";
import { useInvoice } from "../hooks/useInvoice";
import { useDataListURLData } from "shared/components/dataListURL/useDataListURLData";
import { DownloadOutlined, MailOutlined } from "@ant-design/icons";
import { downloadInvoice, downloadMultipleInvoices } from "../utils";
import { message } from "antd";

export const InvoiceTable = () => {
  const { useFetchInvoices, useEmailInvoice } = useInvoice();
  const [openFilter, setOpenFilter] = useState(false);
  const { windowInnerHeight } = useWindowSize();
  const { globalFilter, setGlobalFilter } = useDataListURLGlobalFilter();
  const { data, isLoading } = useFetchInvoices();
  const { mutate: emailInvoice, isLoading: isEmailLoading } = useEmailInvoice();

  const { selectedItems } = useDataListURLData<keyof Invoice, Invoice>({
    data,
    isLoading,
    isError: false,
  });

  const generateToolbarContents: (
    record?: Invoice,
  ) => ToolbarButton = record => {
    if (!record) return {};
    return {
      DownloadPDF: {
        showInContextMenu: true,
        showInInlineMenu: true,
        onClick: () => downloadInvoice(record),
        extraInfo: {
          title: "Download",
          tooltipText: "Download",
          icon: <DownloadOutlined />,
        },
      },
      CustomButton: {
        showInContextMenu: true,
        showInInlineMenu: true,
        loading: isEmailLoading,
        onClick: () => {
          emailInvoice([record.id], {
            onSuccess: () => {
              message.success("Email sent successfully!");
            },
            onError: (error: any) => {
              message.error(
                `Failed to send email: ${error.message || "Unknown error"}`,
              );
            },
          });
        },
        extraInfo: {
          title: "Email",
          text: "Email",
          tooltipText: "Email",
          icon: <MailOutlined />,
        },
      },
    };
  };

  const toolbarContents = {
    DownloadPDF: {
      label: "Download",
      disabled: selectedItems.length === 0,
      onClick: () => downloadMultipleInvoices(selectedItems),
      extraInfo: {
        title: "Download",
        tooltipText: "Download",
        icon: <DownloadOutlined />,
      },
    },
    CustomButton: {
      label: "Email",
      disabled: selectedItems.length === 0,
      loading: isEmailLoading,
      onClick: () => {
        const invoiceIds = selectedItems.map(invoice => invoice.id);
        emailInvoice(invoiceIds, {
          onSuccess: () => {
            message.success("Email sent successfully!");
          },
          onError: (error: any) => {
            message.error(
              `Failed to send email: ${error.message || "Unknown error"}`,
            );
          },
        });
      },
      extraInfo: {
        title: "Email",
        text: "Email",
        tooltipText: "Send invoice by Email",
        icon: <MailOutlined />,
      },
    },
  };

  return (
    <div className={styles.container}>
      <ToolbarTable
        searchValue={globalFilter ?? undefined}
        disableSearch={false}
        toolbarContents={toolbarContents}
        searchPlaceholder="Search"
        titleTooltip="Search Invoices"
        onSearch={value => setGlobalFilter(value)}
      />

      {data && (
        <DataListTagsURL<keyof Invoice, Invoice>
          data={data}
          originalData={data}
          onAddFilterClick={() => setOpenFilter(true)}
        />
      )}

      <TableActionsContextProvider<Invoice>
        renderContextActions={record => (
          <ContextTableActions
            getItemsToRender={() => generateToolbarContents(record)}
            record={record}
          />
        )}
        renderHoverActions={record => (
          <InlineTableActions
            getItemsToRender={() => generateToolbarContents(record)}
            record={record}
          />
        )}
      >
        <DataListURLTable<keyof Invoice, Invoice>
          type="hoverActionsVirtualTable"
          loading={isLoading}
          dataSource={data}
          pagination={false}
          locale={{
            emptyText: <EmptyInvoice />,
          }}
          size="small"
          scroll={{ y: windowInnerHeight - 245 }}
          columns={generateInvoiceColumns()}
          fieldKeys={Object.keys(fields) as (keyof Invoice)[]}
        />
      </TableActionsContextProvider>

      <InvoiceFilterDrawer
        invoiceTableData={data ?? []}
        open={openFilter}
        onClose={() => setOpenFilter(false)}
      />
    </div>
  );
};

export default InvoiceTable;

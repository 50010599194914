import { MappingDataForExportPortal } from "shared/types/assetExporter";
import { PortalOptions } from "../../shared/types";
import { PortalOptionMatchTableData } from "../types";

export const getUpdatedMatches = (
  previousMatches: MappingDataForExportPortal,
  portalOption: PortalOptions | "",
  rowData: PortalOptionMatchTableData,
) => {
  const cleanedMatches = {
    tags: previousMatches.tags?.filter(key => key !== rowData.key) || [],
    metas: previousMatches.metas?.filter(key => key !== rowData.key) || [],
    notInclude:
      previousMatches.notInclude?.filter(key => key !== rowData.key) || [],
  };

  if (!portalOption) {
    return cleanedMatches;
  }

  switch (portalOption) {
    case PortalOptions.Filters:
      return {
        ...cleanedMatches,
        tags: [...cleanedMatches.tags, rowData.key],
      };
    case PortalOptions.Metadata:
      return {
        ...cleanedMatches,
        metas: [...cleanedMatches.metas, rowData.key],
      };
    case PortalOptions.DoNotImport:
      return {
        ...cleanedMatches,
        notInclude: [...cleanedMatches.notInclude, rowData.key],
      };
    default:
      return cleanedMatches;
  }
};

import { Alert, Card, Typography } from "antd";
import { PrintOptionsRangeQuantity } from "shared/types/printOptions";
import styles from "./PrintOptionProductionTimeCard.module.scss";
import { useMemo } from "react";

export const PrintOptionProductionTimeCard = ({
  productionTime,
}: {
  productionTime: PrintOptionsRangeQuantity;
}) => {
  const time = useMemo(() => {
    const { min, max } = productionTime;
    if (min === max || !max) {
      return min + " day";
    }
    return `${min}-${max} days`;
  }, [productionTime]);

  return (
    <Card className={styles.card}>
      <Typography.Text>Est. Production time: {time}</Typography.Text>
      <Alert
        className={styles.alert}
        showIcon
        message="Production time is estimated. Delivery time is not included."
        type="info"
      />
    </Card>
  );
};

import { message } from "antd";
import {
  useQuery,
  useMutation,
  useQueryClient,
  UseQueryOptions,
} from "react-query";
import API from "services";
import { getResultIfOk } from "services/adLibrary";
import { Invoice } from "shared/types/salesEnablement";
import { getEnvVar } from "utils/helpers";

const fetchInvoices = async (): Promise<Invoice[]> => {
  const { result } = getResultIfOk(
    await API.services.salesEnablement.getInvoices(),
  );
  return result;
};

const fetchInvoiceById = async (id: string): Promise<Invoice> => {
  const { result } = getResultIfOk(
    await API.services.salesEnablement.getInvoiceById(id),
  );
  return result;
};

const downloadInvoicesById = async (invoiceIds: string[]) => {
  const { result } = getResultIfOk(
    await API.services.salesEnablement.downloadInvoices(invoiceIds),
  );
  return result;
};

const emailInvoiceById = async (invoiceIds: string[]) => {
  const emailIds: string[] = JSON.parse(getEnvVar("INVOICE_EMAIL_IDS"));
  const payload = {
    invoiceIds,
    emailIds,
  };
  const { result } = getResultIfOk(
    await API.services.salesEnablement.emailInvoices(payload),
  );
  return result;
};

export const useInvoice = () => {
  const queryClient = useQueryClient();

  const useFetchInvoices = (options: UseQueryOptions<Invoice[], Error> = {}) =>
    useQuery<Invoice[], Error>({
      queryKey: ["invoices"],
      queryFn: fetchInvoices,
      staleTime: 5 * 60 * 1000,
      ...options,
    });

  const useFetchInvoiceById = (
    id: string,
    options: UseQueryOptions<Invoice, Error> = {},
  ) =>
    useQuery<Invoice, Error>({
      queryKey: ["invoice", id],
      queryFn: () => fetchInvoiceById(id),
      enabled: !!id,
      ...options,
    });

  const useDownloadInvoices = () =>
    useMutation(downloadInvoicesById, {
      onSuccess: () => {
        queryClient.invalidateQueries(["invoices"]);
      },
      onError: () => {
        message.error("Failed to download invoices");
      },
    });

  const useEmailInvoice = () =>
    useMutation(emailInvoiceById, {
      onSuccess: () => {
        queryClient.invalidateQueries(["invoices"]);
      },
      onError: () => {
        message.error("Failed to email invoices");
      },
    });

  return {
    useFetchInvoices,
    useFetchInvoiceById,
    useDownloadInvoices,
    useEmailInvoice,
  };
};

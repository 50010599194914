import { Button, DatePicker, Popover, Tooltip } from "antd";
import styles from "./RunDate.module.scss";
import { useMemo, useState } from "react";
import { IAd } from "shared/types/adLibrary";
import moment from "moment";
import classNames from "classnames";
import { useLocation } from "react-router-dom";
import {
  hasValidDateRange,
  hasValidRangeOrder,
  toDateRange,
} from "./RunDate.utils";

type RunDate = { start?: Date; end?: Date };
type Props<T extends IAd = IAd> = {
  ad: T;
  value?: RunDate;
  onComplete: (args: {
    range: [start: Date | null, end: Date | null];
    toAll: boolean;
    ad: T;
    isPlannerInstance: boolean;
  }) => void;
};
const RunDate = <T extends IAd = IAd>({ ad, value, onComplete }: Props<T>) => {
  const [range, setRange] = useState<RunDate | undefined>(value);
  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const { pathname } = useLocation();

  const rangeMoment = useMemo(() => {
    const { start, end } = range ?? {};

    return {
      start: start ? moment(start) : undefined,
      end: end ? moment(end) : undefined,
    };
  }, [range]);

  const apply = (toAll: boolean, isPlannerInstance: boolean) => {
    if (!range) return;
    const { start, end } = range;

    const isRemoving = hasValidDateRange(value) && !end;

    if (!isRemoving && !end) {
      setErrorMessage("End date is required");
      return;
    }

    if (isRemoving && !!start) {
      setErrorMessage("Please clear the start date.");
      return;
    }

    if (!!start && !!end && !hasValidRangeOrder({ start, end })) {
      setErrorMessage("The end date must be after the start date.");
      return;
    }

    onComplete({
      range: [start ?? null, end ?? null],
      toAll,
      ad: ad as T,
      isPlannerInstance,
    });
    setOpen(false);
  };

  const onDateChange = (date: Date | null, which: "start" | "end") => {
    setRange(prev => ({
      ...prev,
      [which]: date,
    }));
  };

  const forPlannerInstance =
    ad.instances?.some(inst => inst.name === ad.source) ?? false;
  const plannerInstanceLevel = pathname.includes("instance");
  const getClassName = () => {
    if (!hasValidDateRange(value)) return;

    if (plannerInstanceLevel) {
      return forPlannerInstance ? styles.valid : undefined;
    }

    return styles.valid;
  };
  const campaignPlannerLevel = !plannerInstanceLevel && !ad.source;

  return (
    <Popover
      trigger="click"
      visible={
        open &&
        (campaignPlannerLevel || (plannerInstanceLevel && forPlannerInstance))
      }
      onVisibleChange={open => {
        setOpen(open);
      }}
      content={
        <div className={styles.content}>
          <div className={styles.header}>
            <div className={styles.title}>Edit Run Date</div>
            <div className={styles.description}>
              With an end date set: Loaded status change to Pause Requested, and
              Pending or Load Requested change to Do Not Load.
            </div>
          </div>
          <div className={styles.datePickerContainer}>
            <DatePicker
              allowClear
              placeholder="Start date"
              defaultValue={rangeMoment.start}
              onChange={date => onDateChange(date?.toDate() ?? null, "start")}
            />

            <DatePicker
              allowClear
              className={classNames(
                styles["date-picker"],
                !!errorMessage ? styles.error : undefined,
              )}
              placeholder="End date"
              defaultValue={rangeMoment.end}
              onChange={date => onDateChange(date?.toDate() || null, "end")}
            />
          </div>

          {errorMessage && <div className={styles.error}>{errorMessage}</div>}
          <div className={styles.footer}>
            <Button
              type="link"
              onClick={() => apply(false, campaignPlannerLevel)}
            >
              Apply
            </Button>
            <Button
              type="link"
              onClick={() => apply(true, plannerInstanceLevel)}
            >
              Apply to all
            </Button>
          </div>
        </div>
      }
    >
      <div
        className={classNames(styles.clickToSelect, getClassName())}
        onClick={e => {
          e.stopPropagation();
          setOpen(true);
        }}
      >
        <Tooltip
          title={
            "Only client request ads can have the run dates edited from the planner instance"
          }
          visible={
            plannerInstanceLevel && !forPlannerInstance ? undefined : false
          }
        >
          {hasValidDateRange(value) && toDateRange(value)}
          {!hasValidDateRange(value) && "Click to select"}
        </Tooltip>
      </div>
    </Popover>
  );
};

export default RunDate;

import { InfoCircleOutlined } from "@ant-design/icons";
import { Checkbox, DatePicker, Form, Input, Select } from "antd";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { dateToEST } from "screens/designStudio/helpers";
import { useSalesEnablementContext } from "screens/designStudio/hooks/SalesEnablementDataProvider";
import LocationsInput from "shared/components/LocationsInput";
import { PrintOptionsField } from "./formFieds/PrintOptionsField";
import styles from "./FormFields.module.scss";
import { fields } from "./FormFields.schema";

type Props = {
  disabled?: boolean;
};

export const SettingsFields = ({ disabled }: Props) => (
  <div className={styles.container}>
    <div className={styles.fieldsContainer}>
      <Form.Item
        labelAlign="left"
        label={fields.name.label}
        name={fields.name.key}
        rules={[
          {
            required: fields.name.required,
            message: `${fields.name.label} is required.`,
          },
        ]}
      >
        <Input placeholder={fields.name.placeholder} disabled={disabled} />
      </Form.Item>
      <Form.Item
        labelAlign="left"
        label={fields.description.label}
        name={fields.description.key}
        rules={[
          {
            required: fields.description.required,
            message: `${fields.description.label} is required.`,
          },
        ]}
      >
        <Input placeholder={fields.description.placeholder} />
      </Form.Item>
      <Form.Item
        labelAlign="left"
        label={fields.locations.label}
        name={fields.locations.key}
        rules={[
          {
            required: fields.locations.required,
            message: `${fields.locations.label} is required.`,
          },
        ]}
      >
        <LocationsInput disabled={disabled} />
      </Form.Item>

      <Form.Item
        requiredMark={fields.salesCycle.required ? true : "optional"}
        label={fields.salesCycle.label}
        name={fields.salesCycle.key}
        rules={[
          {
            required: fields.salesCycle.required,
            message: `${fields.salesCycle.label} is required.`,
          },
        ]}
      >
        <Select
          mode="multiple"
          placeholder={fields.salesCycle.placeholder}
          options={fields.salesCycle.options}
        />
      </Form.Item>
      <Form.Item
        requiredMark={fields.audience.required ? true : "optional"}
        label={fields.audience.label}
        name={fields.audience.key}
        rules={[
          {
            required: fields.audience.required,
            message: `${fields.audience.label} is required.`,
          },
        ]}
      >
        <Select
          mode="multiple"
          placeholder={fields.audience.placeholder}
          options={fields.audience.options}
        />
      </Form.Item>
      <Form.Item
        requiredMark={fields.salesCycle.required ? true : "optional"}
        label={fields.materialType.label}
        name={fields.materialType.key}
        rules={[
          {
            required: fields.materialType.required,
            message: `${fields.materialType.label} is required.`,
          },
        ]}
      >
        <Select
          mode="multiple"
          placeholder={fields.materialType.placeholder}
          options={fields.materialType.options}
        />
      </Form.Item>
    </div>
  </div>
);

export const ComponentsFields = ({
  lang,
  active,
}: {
  active: boolean;
  lang: "en" | "es";
}) => {
  const [expirationDate, setExpirationDate] = useState<
    moment.Moment | undefined
  >(undefined);

  const formNumberField =
    lang === "en" ? fields.englishFormNumber : fields.spanishFormNumber;
  const defaultEmailSubject =
    lang === "en"
      ? fields.englishDefaultEmailSubject
      : fields.spanishDefaultEmailSubject;

  const { uploadForm, files } = useSalesEnablementContext();

  const file = useMemo(() => files[lang], [files, lang]);

  useEffect(() => {
    if (!uploadForm || !file) return;
    const formDeliveryMethods = uploadForm?.getFieldValue(
      `${fields.deliveryMethods.key}_${lang}`,
    );

    if (!formDeliveryMethods || formDeliveryMethods.length === 0) {
      let defaultDeliveryMethods = file?.deliveryMethods || [];

      if (!file?.deliveryMethods) {
        if (file?.type === "html") {
          defaultDeliveryMethods = ["email"];
        } else if (file?.type === "pdf" || file?.type === "indesign") {
          defaultDeliveryMethods = ["download"];
        }
      }

      uploadForm?.setFieldsValue({
        [`${fields.deliveryMethods.key}_${lang}`]: defaultDeliveryMethods,
      });
    }

    const expirationValue = uploadForm?.getFieldValue("expirationDate");
    if (expirationValue) {
      setExpirationDate(dateToEST(moment.utc(expirationValue)));
    } else {
      setExpirationDate(undefined);
    }
  }, [active, uploadForm, file, lang]);

  const isHTMLTemplate = file?.type === "html";
  const isPDFTemplate = file?.type === "pdf";

  const deliveryMethodsFiledName = `${fields.deliveryMethods.key}_${lang}`;

  const availableDeliveryMethods = useMemo(() => {
    if (!file) return;

    const { type } = file;
    if (!type) throw new Error("file must have type.");
    switch (type) {
      case "html":
        return ["email"];
      case "pdf":
        return ["download"];
      case "indesign":
        return ["download", "print"];
    }
  }, [file]);

  return (
    <div className={styles.container} style={{ padding: `12px 0 0` }}>
      <div className={styles.fieldsContainer}>
        <div className={styles.expirationDateContainer}>
          <Form.Item
            labelAlign="left"
            label={fields.expirationDate.label}
            tooltip={{
              title: `
               Set an expiration date to automate when this template is unpublished and inaccessible to agents.
               Admins can update the expiration date to re-publish an expired template.
              `,
              icon: <InfoCircleOutlined />,
            }}
            name={fields.expirationDate.key}
            key={fields.expirationDate.key}
            rules={[
              {
                required: fields.expirationDate.required,
                message: `${fields.expirationDate.label} is required.`,
              },
            ]}
            valuePropName={"date"}
          >
            <DatePicker
              value={expirationDate}
              onChange={date => {
                if (!date) return setExpirationDate(undefined);
                setExpirationDate(dateToEST(moment.utc(date)));
              }}
              picker="month"
              name={fields.expirationDate.key}
              format={date => dateToEST(date).format("M/YY")}
              placeholder="Please select"
              className={styles.datePicker}
              disabledDate={d => {
                return dateToEST(moment.utc()).isSameOrAfter(
                  dateToEST(d),
                  "month",
                );
              }}
            />
          </Form.Item>
          <Form.Item
            labelAlign="left"
            label={formNumberField.label}
            name={formNumberField.key}
            key={formNumberField.key}
            rules={[
              {
                required: formNumberField.required,
                message: `Form Number is required.`,
              },
            ]}
          >
            <Input placeholder="Enter Form Number" />
          </Form.Item>
        </div>
        <Form.Item
          labelAlign="left"
          label={fields.deliveryMethods.label}
          tooltip={{
            title: isHTMLTemplate
              ? "HTML Templates may only have Email as the Delivery Method."
              : isPDFTemplate
              ? "PDF Templates may only have Download as the Delivery Method."
              : "Select at least one delivery method.",
            icon: <InfoCircleOutlined />,
          }}
          name={deliveryMethodsFiledName}
          rules={[
            {
              required: fields.deliveryMethods.required,
              message: "Please select at least one delivery method option.",
            },
          ]}
        >
          <Checkbox.Group
            value={uploadForm?.getFieldValue(deliveryMethodsFiledName)}
            onChange={checkedValues => {
              uploadForm?.setFieldsValue({
                [deliveryMethodsFiledName]: checkedValues,
              });
              if (!checkedValues?.includes("print")) {
                uploadForm?.setFieldsValue({
                  printOptionId: undefined,
                });
              }
            }}
          >
            {availableDeliveryMethods?.map(deliveryMethod => (
              <Checkbox
                key={`deliveryMethod-${deliveryMethod}`}
                value={deliveryMethod}
              >
                {deliveryMethod}
              </Checkbox>
            ))}
          </Checkbox.Group>
        </Form.Item>

        <Form.Item
          noStyle
          shouldUpdate={(prev, curr) =>
            prev[deliveryMethodsFiledName] !== curr[deliveryMethodsFiledName]
          }
          dependencies={[deliveryMethodsFiledName, "printOptionId"]}
        >
          {({ getFieldValue }) => {
            const deliveryMethods = getFieldValue(deliveryMethodsFiledName);
            if (!deliveryMethods?.includes("print")) return null;
            return (
              <Form.Item
                name="printOptionId"
                labelAlign="left"
                label="Print Options"
                rules={[
                  {
                    required: true,
                    message: "Please select at least one print option.",
                  },
                ]}
                shouldUpdate
              >
                <PrintOptionsField />
              </Form.Item>
            );
          }}
        </Form.Item>
        {isHTMLTemplate && (
          <Form.Item
            labelAlign="left"
            label={defaultEmailSubject.label}
            name={defaultEmailSubject.key}
          >
            <Input />
          </Form.Item>
        )}
      </div>
    </div>
  );
};

import { Badge, Typography } from "antd";
import { EditableCellType } from "shared/components/dataList/EditableCell";
import { EditableColumnParameters } from "shared/components/dataList/setupEditableCells";
import { ITableColumnURL } from "shared/components/dataListURL/DataListURLTable";
import {
  ArchiveItem,
  PrintArchiveTableKeys,
} from "shared/types/salesEnablementArchive";

const OrderStatusMap = {
  IN_PROGRESS: {
    color: "blue",
    text: "In Progress",
  },
  COMPLETE: {
    color: "green",
    text: "Complete",
  },
};

const { Link } = Typography;

export type PrintArchiveColumn = ITableColumnURL<
  PrintArchiveTableKeys,
  ArchiveItem
> &
  EditableColumnParameters<ArchiveItem>;

export const getColumns = (
  openOrderDetails: (order: ArchiveItem) => void,
): PrintArchiveColumn[] => [
  {
    title: "Order ID",
    dataIndex: "id",
    key: "id",
    render: (_, record) => (
      <Link onClick={() => openOrderDetails(record)}>{record.id}</Link>
    ),
  },
  {
    title: "Order Date",
    dataIndex: "createdAt",
    key: "createdAt",
    render: (_, record) =>
      record?.createdAt
        ? new Date(record?.createdAt).toLocaleDateString("en-US")
        : "-",
  },
  {
    title: "Recipient",
    dataIndex: "recipient",
    key: "recipient",
    render: (_, record) =>
      record.materialPrintOrderData?.shippingData?.fullName,
  },
  {
    title: "Estimated Amount",
    dataIndex: ["materialPrintOrderData", "amount"],
    key: "estimatedAmount",
    render: (_, record) => record.materialPrintOrderData?.amount.toFixed(2),
  },
  {
    title: "Order Status",
    dataIndex: "orderStatus",
    key: "orderStatus",
    editable: true,
    editableCellType: EditableCellType.Select,
    selectOptions: [
      { key: 0, value: "IN_PROGRESS", text: "In Progress" },
      { key: 1, value: "COMPLETE", text: "Complete" },
    ],
    render: (_, record) => {
      const orderStatus = record.orderStatus ?? "IN_PROGRESS";
      const currentStatus = OrderStatusMap[orderStatus];
      return (
        <span>
          <Badge color={currentStatus?.color ?? "default"} />
          {currentStatus?.text}
        </span>
      );
    },
  },
  {
    title: "Completion Date",
    dataIndex: "deliveryDate",
    key: "deliveryDate",
  },
  {
    title: "Completed By",
    dataIndex: "agent",
    key: "agent",
  },
];

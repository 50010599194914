import { Empty } from "antd";
import styles from "./EmptyInvoice.module.scss";

const EmptyInvoice = () => {
  return (
    <div className={styles.empty}>
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description="No invoices found"
        className={styles.icon}
      />
    </div>
  );
};

export default EmptyInvoice;

import { Button } from "antd";
import styles from "./EmailDrawerFooter.module.scss";

const EmailDrawerFooter = ({
  onClose,
  sendEmail,
  isLoading,
}: {
  onClose: () => void;
  sendEmail: () => void;
  isLoading: boolean;
}) => {
  return (
    <div className={styles.footer}>
      <Button type="default" htmlType="button" onClick={onClose}>
        Cancel
      </Button>
      <Button
        type="primary"
        htmlType="button"
        onClick={sendEmail}
        loading={isLoading}
      >
        Send
      </Button>
    </div>
  );
};

export default EmailDrawerFooter;

import { InputNumber, Select } from "antd";
import {
  PrintOptionCopyType,
  PrintOptionItem,
} from "shared/types/printOptions";

type Props = {
  printOption: PrintOptionItem;
  value?: number;
  onChange: (value: number) => void;
};
const CopiesField = ({ printOption, value, onChange }: Props) => {
  if (!onChange) return null;
  const { costPerCopy, copySelection } = printOption;

  if (copySelection === PrintOptionCopyType.RANGE) {
    const min = costPerCopy[0]?.quantity?.min ?? 1;
    const max = costPerCopy[costPerCopy.length - 1]?.quantity?.max;
    return <InputNumber min={min} max={max} onChange={onChange} />;
  }
  return (
    <Select
      value={value}
      onChange={onChange}
      options={costPerCopy.map(c => ({
        label: c.quantity.toString(),
        value: c.quantity,
      }))}
    />
  );
};

export default CopiesField;

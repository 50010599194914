import { useMemo, useState } from "react";
import { useFetchMarketingMaterials } from "./useFetchMarketingMaterials";
import { useFetchTemplates } from "shared/hooks/designStudio/useFetchTemplates";
import { Language } from "shared/types/salesEnablement";
import { useFetchPrintOptions } from "shared/hooks/salesEnablement/useFetchPrintOptions";

export function useSelectedMarketingMaterial({
  materialId,
  templateId,
}: {
  materialId?: string;
  templateId?: string;
}) {
  const { data: printOptions, isLoading: isPrintOptionLoading } =
    useFetchPrintOptions();
  const { marketingMaterials, isLoading } = useFetchMarketingMaterials();
  const { templates, isTemplateLoading } = useFetchTemplates();

  const material = useMemo(() => {
    return marketingMaterials.find(m => m.id === materialId);
  }, [marketingMaterials, materialId]);

  const template = useMemo(() => {
    const currentTemplate = material
      ? templates.find(t => t.id === material?.templateId)!
      : templates.find(t => t.id === templateId)!;
    return currentTemplate;
  }, [material, templates, templateId]);

  const [language, setLanguage] = useState<Language>(() => {
    if (material?.language) return material.language;
    if (template) return Object.keys(template.files)[0] as Language;
    return "en";
  });

  const printOption = useMemo(() => {
    return printOptions?.find(option => option.id === template?.printOptionId);
  }, [printOptions, template]);

  const templateFile = useMemo(
    () => ({ ...template?.files[language || "en"], status: "done" } as const),
    [template, language],
  );

  return {
    material,
    template,
    templateFile,
    isLoading: isLoading || isTemplateLoading || isPrintOptionLoading,
    printOption,
    language,
    setLanguage,
  };
}

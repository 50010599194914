import { IDataListAction, IDataListReducerState } from "./types";
import {
  createConditions,
  normalizeSortPayload,
  removeEmptyFilters,
  removeFilterValue,
  updateFilterValue,
} from "./utils";

export const reducer = (
  state: IDataListReducerState,
  action: IDataListAction,
): IDataListReducerState => {
  switch (action.type) {
    case "SET_FILTERS": {
      const { tableId, filters } = action.payload;
      const updatedFilters = removeEmptyFilters(filters);
      const conditions = createConditions(
        {
          ...state,
          filters: {
            ...state.filters,
            [tableId]: updatedFilters,
          },
          globalFilter: "",
        },
        tableId,
      );

      return {
        ...state,
        filters: {
          ...state.filters,
          [tableId]: updatedFilters,
        },
        conditions: {
          ...state.conditions,
          [tableId]: conditions,
        },
      };
    }

    case "SET_GLOBAL_FILTER": {
      const { value } = action.payload;
      const globalFilter = value;
      const globalConditions = createConditions({
        ...state,
        globalFilter,
        filters: {},
      });

      return {
        ...state,
        globalFilter,
        globalConditions,
      };
    }

    case "UPDATE_FILTER": {
      const { tableId, key, value } = action.payload;
      const updatedFilters = updateFilterValue(
        state.filters[tableId],
        key,
        value,
      );
      const conditions = createConditions(
        {
          ...state,
          filters: {
            ...state.filters,
            [tableId]: updatedFilters,
          },
          globalFilter: "",
        },
        tableId,
      );

      return {
        ...state,
        filters: {
          ...state.filters,
          [tableId]: updatedFilters,
        },
        conditions: {
          ...state.conditions,
          [tableId]: conditions,
        },
      };
    }

    case "REMOVE_FILTER": {
      const { tableId, key, value } = action.payload;
      const updatedFilters = removeFilterValue(
        state.filters[tableId],
        key,
        value,
      );
      const conditions = createConditions(
        {
          ...state,
          filters: {
            ...state.filters,
            [tableId]: updatedFilters,
          },
          globalFilter: "",
        },
        tableId,
      );

      return {
        ...state,
        filters: {
          ...state.filters,
          [tableId]: updatedFilters,
        },
        conditions: {
          ...state.conditions,
          [tableId]: conditions,
        },
      };
    }

    case "RESET_FILTERS": {
      const { tableId } = action.payload;
      const filters = {
        ...state.filters,
        [tableId]: {},
      };
      const conditions = createConditions(
        {
          ...state,
          filters,
          globalFilter: "",
        },
        tableId,
      );

      return {
        ...state,
        filters,
        conditions: {
          ...state.conditions,
          [tableId]: conditions,
        },
      };
    }

    case "SORT_ITEMS": {
      const { order, columnKey } = normalizeSortPayload(action.payload);
      const sort = { columnKey, order };

      return {
        ...state,
        sort,
      };
    }

    case "TOGGLE_SELECTED_ITEMS": {
      const showSelected = !state.showSelected;

      return {
        ...state,
        showSelected,
      };
    }

    case "SET_SELECTED_ITEMS": {
      const { ids } = action.payload;
      const selectedIds = ids;
      const showSelected = !ids.length ? false : state.showSelected;

      return {
        ...state,
        selectedIds,
        showSelected,
      };
    }

    case "TOGGLE_SELECTED_ITEM": {
      const { id } = action.payload;
      const ids = !state.selectedIds.includes(id)
        ? [...state.selectedIds, id]
        : state.selectedIds.filter(sid => sid !== id);
      const showSelected = !ids.length ? false : state.showSelected;

      return {
        ...state,
        selectedIds: ids,
        showSelected,
      };
    }

    case "SET_SELECT_ALL_ITEMS": {
      const { value } = action.payload;
      return {
        ...state,
        selectAll: value,
      };
    }

    default: {
      throw new Error(`Unknown action type`);
    }
  }
};

import { Button } from "antd";
import styles from "./ExportDrawer.module.scss";
import { ExportDestination } from "./types";
import { useFeedContext } from "../../shared/contexts/FeedContext";
import { useAssetBatchExportGeneration } from "shared/hooks/assetExporter/useAssetBatchExportGeneration";
import { useAdLibraryExportContext } from "../adLibrary/AdLibraryExportContext";
import { isFinalExportStep } from "./utils/isFinalExportStep";
import { useOffPlatformExportContext } from "../offPlatform/OffPlatformExportContext";
import { usePortalExportContext } from "../portal/PortalExportContext";
import { FooterNextButton } from "./FooterNextButton";
import { getPortalDataOptions } from "./utils/getPortalDataOptions";
import { useMemo } from "react";
import { usePortalExportData } from "../portal/usePortalExportData";
import { IFilter } from "shared/hooks/assetExporter/useFeedConfigFetch";

interface FooterProps {
  onClose: () => void;
  currentStep: number;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  filterArgs?: IFilter[];
  searchValue: string;
}

export const Footer = ({
  onClose,
  currentStep,
  setCurrentStep,
  filterArgs,
  searchValue,
}: FooterProps) => {
  const { selectedAssetBatches, feedId, selectedRows } = useFeedContext();

  const {
    exportDestination,
    adType,
    setAdType,
    columnMatches,
    setColumnMatches,
    adInfoUpdates,
    setAdInfoUpdates,
  } = useAdLibraryExportContext();

  const { portalOptionMatches, updatePortalOptionMatches } =
    useOffPlatformExportContext();

  const { formValues, updateFormValues } = usePortalExportContext();

  const { brands, accounts, clients, loading } = usePortalExportData();
  const clientSlug = useMemo(() => {
    const currentClient = clients?.find(
      client => client.id === formValues.clientId,
    );
    return currentClient?.slug ?? "";
  }, [clients, formValues.clientId]);

  const brandsOptions = useMemo(
    () => getPortalDataOptions(brands, loading),
    [brands, loading],
  );
  const accountsOptions = useMemo(
    () => getPortalDataOptions(accounts, loading),
    [accounts, loading],
  );

  const portalData = {
    ...formValues,
    clientId: formValues.clientId,
    ...(formValues.brandColumn && { brands: brandsOptions }),
    ...(formValues.accountColumn && { accounts: accountsOptions }),
  };

  const { handleStartExportAssetBatches, isStartingExport } =
    useAssetBatchExportGeneration({
      feedId,
      selectedRows,
      selectedAssetBatches: selectedAssetBatches ?? [],
      exportDestination: exportDestination ?? ExportDestination.CSVDownload,
      columnMatches,
      adInfoUpdates,
      adType,
      portalOptionMatches,
      portalData,
      clientSlug,
      filterArgs,
      searchValue,
    });

  const onExport = () => {
    handleStartExportAssetBatches({
      onSettled: onClose,
    });
  };

  const resetNextStepState = () => {
    if (currentStep === 0) {
      setAdType(null);
      setColumnMatches({});
      updatePortalOptionMatches();
      updateFormValues({
        brandId: undefined,
        accountId: undefined,
        brandColumn: "",
        accountColumn: "",
        feedColumns: [],
        clientId: undefined,
      });
    }
    if (currentStep === 1) setAdInfoUpdates({});
  };

  const handleNextButtonClick = () => {
    if (isFinalExportStep(currentStep, exportDestination)) onExport();
    else setCurrentStep(previousStep => previousStep + 1);

    resetNextStepState();
  };

  const handleBackButtonClick = () => {
    setCurrentStep(previousStep => previousStep - 1);
  };

  return (
    <div className={styles.footer}>
      <Button onClick={onClose}>Cancel</Button>
      <div className={styles.nextBackButtonsContainer}>
        {currentStep > 0 && (
          <Button onClick={handleBackButtonClick}>Back</Button>
        )}
        <FooterNextButton
          handleNextButtonClick={handleNextButtonClick}
          currentStep={currentStep}
          isStartingExport={isStartingExport}
        />
      </div>
    </div>
  );
};

import { SearchOutlined } from "@ant-design/icons";
import { Tag } from "antd";
import { isObject } from "lodash";
import { Link } from "react-router-dom";
import { ITableColumnURL } from "shared/components/dataListURL/DataListURLTable";
import { FilterDropdownWithFilterSearch } from "shared/components/FilterDropdownWithFilterSearch";
import {
  NestedPrintOptionItem,
  PrintOptionsTableColumns,
  PrintOptionTableItem,
} from "shared/types/printOptions";
import { ROUTES } from "../constants";

export const getColumns = <
  T extends PrintOptionTableItem | NestedPrintOptionItem,
>(
  isAdmin?: boolean,
): ITableColumnURL<PrintOptionsTableColumns, T>[] => [
  {
    key: "name",
    title: "Name",
    dataIndex: ["name"],
    align: "left",
    filterDropdown: FilterDropdownWithFilterSearch,
    filterIcon: <SearchOutlined />,
    width: 400,
    render: (_, record) =>
      isAdmin ? (
        <Link to={ROUTES.edit(record.id)}>{record.name}</Link>
      ) : (
        record.name
      ),
  },
  {
    key: "printMode",
    title: "Print Mode",
    dataIndex: ["printMode"],
    filterDropdown: FilterDropdownWithFilterSearch,
    align: "left",
    filterIcon: <SearchOutlined />,
    width: 160,
    render: (_, record) => record?.printMode?.map(m => <Tag key={m}>{m}</Tag>),
  },
  {
    key: "copyQuantity",
    title: "Copy Quantity",
    dataIndex: ["costPerCopy", "quantity"],
    filterDropdown: FilterDropdownWithFilterSearch,
    align: "left",
    width: 160,
    filterIcon: <SearchOutlined />,
    render: (_, record) => {
      if (record.costPerCopy.length > 1) {
        return "";
      }
      const quantity = record.costPerCopy[0].quantity;
      if (typeof quantity === "number") {
        return quantity;
      }
      const { min, max } = quantity;
      return max ? `${min} - ${max}` : `${min}+`;
    },
  },
  {
    key: "copyCost",
    title: "Cost",
    dataIndex: ["costPerCopy", "cost"],
    filterDropdown: FilterDropdownWithFilterSearch,
    align: "left",
    width: 160,
    filterIcon: <SearchOutlined />,
    render: (_, record) => {
      return (
        !isObject(record?.costPerCopy[0].cost) &&
        `$${record.costPerCopy[0].cost}`
      );
    },
  },
  {
    key: "weight",
    title: "Weight",
    dataIndex: ["weight"],
    align: "left",
    width: 160,
    filterDropdown: FilterDropdownWithFilterSearch,
    filterIcon: <SearchOutlined />,
  },
  {
    key: "associatedTemplates",
    title: "Associated Templates",
    dataIndex: ["associatedTemplates"],
    align: "left",
    width: 160,
    filterDropdown: FilterDropdownWithFilterSearch,
    filterIcon: <SearchOutlined />,
    render: (_, record) => record.associatedTemplates?.length ?? 0,
  },
  {
    key: "referenceNumber",
    title: "Reference No",
    dataIndex: ["referenceNumber"],
    align: "left",
    width: 160,
    filterDropdown: FilterDropdownWithFilterSearch,
    filterIcon: <SearchOutlined />,
  },
];

import { PictureOutlined } from "@ant-design/icons";
import { IdmlPreview } from "screens/designStudio/hooks/useFileData";
import styles from "./PDFPreview.module.scss";

const PDFPreview = ({ src, isError, isLoading }: IdmlPreview) => {
  return (
    <div className={styles.container}>
      <div className={styles.canvasContainer}>
        {src && !isError && (
          <object
            className={styles.iframe}
            data={`${src}#toolbar=0&navpanes=0&view=FitH`}
          />
        )}
        {isLoading && (
          <div className={styles.loading}>
            <PictureOutlined className={styles.icon} />
            <div className={styles.title}>Loading preview...</div>
          </div>
        )}
        {isError && (
          <div className={styles.error}>
            <h1>Unable to render PDF</h1>
          </div>
        )}
      </div>
    </div>
  );
};

export default PDFPreview;

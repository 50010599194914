import { NotificationOutlined } from "@ant-design/icons";
import { Drawer, Button, Tabs } from "antd";
import { useState, useMemo } from "react";
import TemplateRenderDataProvider from "screens/designStudio/hooks/TemplateRenderDataProvider";
import { useIsSalesEnablementAdmin } from "shared/hooks/useIsAdmin";
import { Language, Template } from "shared/types/salesEnablement";
import styles from "./PreviewTemplate.module.scss";
import Preview from "screens/adLibrary/marketingMaterialDrawer/Preview";

type PreviewTemplateProps = {
  visible: boolean;
  onClose: () => void;
  onCreateMarketingMaterial?: () => void;
  templateData?: Template;
};

export const PreviewTemplate = ({
  visible,
  onClose,
  onCreateMarketingMaterial,
  templateData,
}: PreviewTemplateProps) => {
  const [currentLanguage, setCurrentLanguage] = useState<Language>(
    () => templateData?.languages?.[0] ?? "en",
  );

  const isSalesEnablementAdmin = useIsSalesEnablementAdmin();

  const { activeFile } = useMemo(
    () => ({
      activeFile: templateData?.files?.[currentLanguage],
    }),
    [currentLanguage, templateData?.files],
  );

  const multiLanguage = (templateData?.languages?.length ?? 0) > 1;

  return templateData ? (
    <Drawer
      title="Preview Template"
      placement="right"
      onClose={onClose}
      visible={visible}
      closable={false}
      width={`min(calc(100% - 48px), 928px)`}
      style={{ height: "100%" }}
      footer={
        <div className={styles.footer}>
          <Button onClick={onClose}>Close</Button>
          {onCreateMarketingMaterial && (
            <Button
              type="primary"
              icon={<NotificationOutlined />}
              onClick={onCreateMarketingMaterial}
            >
              Create Marketing Material
            </Button>
          )}
        </div>
      }
    >
      <TemplateRenderDataProvider file={activeFile} material={undefined}>
        <div
          className={
            isSalesEnablementAdmin ? styles.contentAdmin : styles.contentAgent
          }
        >
          <Tabs
            activeKey={currentLanguage}
            onChange={activeKey => setCurrentLanguage(activeKey as Language)}
            tabPosition={"top"}
            hideAdd
            className={styles.tabs}
            style={{ height: "100%" }}
            tabBarStyle={{
              visibility: multiLanguage ? "visible" : "hidden",
              marginBottom: "0",
              height: multiLanguage ? "46px" : "0px",
              marginLeft: "24px",
            }}
          >
            {(Object.keys(templateData.files) as Language[])?.map(fileKey => (
              <Tabs.TabPane
                tab={fileKey == "es" ? "Spanish" : "English"}
                key={fileKey}
                style={{ overflow: "auto" }}
              >
                <Preview selectedFile={activeFile} />
              </Tabs.TabPane>
            ))}
          </Tabs>
        </div>
      </TemplateRenderDataProvider>
    </Drawer>
  ) : null;
};

import { FeedTblRow, TAssetBatch } from "shared/types/assetExporter";

export enum FillAdInfoColumnTitle {
  InFeedMedia = "1:1",
  StoryMedia = "9:16",
  AdName = "Ad Name",
  DisplayURL = "Display URL",
  DestinationURL = "Destination URL",
  PrimaryText = "Primary Text",
  Headline = "Headline",
  Description = "Description",
  CTAButton = "CTA Button",
  UTM = "UTM",
  Brand = "Brand",
  Account = "Account",
}

export type FillAdInfoTableData = Partial<
  Record<FillAdInfoColumnTitle, string | null>
>;

export type AdInfoUpdates = Record<string, FillAdInfoTableData>;

export enum ExportDestination {
  AdLibrary = "adLibrary",
  CSVDownload = "csv",
  GoogleSpreadsheet = "googleSpreadsheet",
  Portal = "portal",
}

export enum AdType {
  Still = "Still",
  Video = "Video",
}

export enum RecommendedColumnTitle {
  InFeedMedia = "In feed Image (1:1)",
  StoryMedia = "Story (9:16)",
  AdName = "Ad Name",
  PrimaryText = "Primary Text",
  Headline = "Headline",
  Description = "Description",
  UTM = "UTM",
  Brand = "Brand",
  Account = "Account",
  CTAButton = "CTA Button",
  DisplayURL = "Display URL",
  DestinationURL = "Destination URL",
}

export type RecommendedColumnTitles = Record<string, RecommendedColumnTitle[]>;

export interface RecommendedColumnMatch {
  recommendedColumnTitle: RecommendedColumnTitle | null;
  namingRulePattern?: string;
  assetBatchName?: string;
  assetBatchId?: string;
  assetBatchCreatedAt?: number;
}

export type RecommendedColumnMatches = Record<string, RecommendedColumnMatch>;

export type GetFillAdInfoTableData = ({
  assetBatchesForSelectedRows,
  data,
  feedName,
  feedId,
  columnMatches,
  adType,
  page,
  adInfoUpdates,
}: {
  assetBatchesForSelectedRows: TAssetBatch[];
  data: FeedTblRow[];
  feedName: string;
  feedId: string;
  columnMatches: RecommendedColumnMatches;
  adType: AdType;
  page: number;
  adInfoUpdates: AdInfoUpdates;
}) => FillAdInfoTableData[];

export type HandleSelectColumnMatch = (
  recommendedColumnTitle: RecommendedColumnTitle,
  rowData: MatchColumnsTableData,
) => void;

export type ValidityRecord = Record<RecommendedColumnTitle, boolean>;

export enum PortalOptions {
  Filters = "Filters",
  Metadata = "Metadata",
  DoNotImport = "DoNotImport",
  Filename = "filename",
}

export enum RowType {
  Feed = "Feed",
  AssetName = "Asset Name",
  AssetBatch = "Asset Batch",
  Media = "Media",
}

export type MatchColumnsTableData = {
  title: string;
  key: string;
  rowType: RowType;
  tooltipText?: string;
  thumbnailURL?: string;
  isValidDimensions?: boolean;
  assetBatchName?: string;
  assetBatchId?: string;
};

import { Button } from "antd";
import { useDataList } from "shared/components/dataList";
import { FeedTblRow } from "shared/types/assetExporter";
import styles from "./FeedListTitle.module.scss";
import { useState } from "react";
import { useFeedContext } from "./shared/contexts/FeedContext";
import useGetAssetBatches from "./shared/hooks/useGetAssetBatches";

type Props = {
  feedId: string;
  assetBatchIdsForFilteredRows: string[];
  filteredTotalCount: number;
  setShowSelectAllOption: (showSelectAllOption: boolean) => void;
};

export const FeedListTitle = (props: Props) => {
  const {
    feedId,
    assetBatchIdsForFilteredRows,
    filteredTotalCount,
    setShowSelectAllOption,
  } = props;
  const [dataList, dataListActions] = useDataList<FeedTblRow>();
  const [toggleButton, setToggleButton] = useState(false);
  const { setAssetBatchesForSelectedRows, setEditingAssetBatch, setIsUpdated } =
    useFeedContext();
  const { data: assetBatches } = useGetAssetBatches(feedId, () => {
    setEditingAssetBatch(undefined);
    setIsUpdated(false);
  });
  const onClick = () => {
    dataListActions.setSelectAllItems(!dataList.selectAll);

    if (!toggleButton) {
      const selectedRows = dataList.rows.map(row => row.rowIdentifier);
      dataListActions.setSelectedItems(selectedRows);

      setAssetBatchesForSelectedRows(
        (assetBatches ?? []).filter(
          ({ assetBatchId }) =>
            assetBatchId && assetBatchIdsForFilteredRows.includes(assetBatchId),
        ),
      );
    } else {
      dataListActions.setSelectedItems([]);
      setShowSelectAllOption(false);
      setAssetBatchesForSelectedRows([]);
    }

    setToggleButton(!toggleButton);
  };

  const selectedCount = toggleButton
    ? filteredTotalCount
    : dataList.selectedItems.length;
  return (
    <div className={styles.container}>
      All <b>{selectedCount}</b> rows on this page are selected.&nbsp;
      {!toggleButton ? (
        <Button
          type={"text"}
          className={styles.selectAllButton}
          onClick={onClick}
        >
          Select all {filteredTotalCount} rows in this feed
        </Button>
      ) : (
        <Button
          type={"text"}
          className={styles.selectAllButton}
          onClick={onClick}
        >
          Clear selection
        </Button>
      )}
    </div>
  );
};

import { Input, type InputProps } from "antd";
import styles from "./InputPhone.module.scss";

type Props = Readonly<
  Omit<InputProps, "onChange"> & {
    value?: string;
    onChange?: (value: string) => void;
  }
>;

export const formatPhoneNumber = (phoneNumber: string | undefined) => {
  if (!phoneNumber) return "";

  const phoneOnlyNumbers = phoneNumber?.replace(/\D/g, "");

  if (phoneOnlyNumbers.length <= 3) return phoneOnlyNumbers;

  if (phoneOnlyNumbers.length <= 6) {
    return phoneOnlyNumbers.replace(/(\d{3})(\d{1,3})/, "$1.$2");
  }

  return phoneOnlyNumbers.replace(/(\d{3})(\d{3})(\d{1,4})/, "$1.$2.$3");
};

export const phoneValidator = (value: string | undefined) => {
  if (!value || /^\d{3}\.\d{3}\.\d{4}$/.test(value)) {
    return Promise.resolve();
  }
  return Promise.reject(new Error("Phone numbers must contain 10 digits."));
};

function InputPhone({ value, onChange, ...rest }: Props) {
  if (!onChange) throw new Error("onChange is required");

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (["Tab", "Backspace", "Delete", "Enter"].includes(e.key) || e.metaKey) {
      return;
    }
    if (!/[\d-.]/.test(e.key) || value?.length === 12) {
      e.preventDefault();
    }
  };

  return (
    <Input
      className={styles.removeHandlers}
      value={value}
      maxLength={12}
      onChange={value => {
        onChange(formatPhoneNumber(value.target.value));
      }}
      {...rest}
      style={{ width: "100%" }}
      onKeyDown={onKeyDown}
    />
  );
}
export default InputPhone;

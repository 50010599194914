import { Button, Drawer } from "antd";
import TemplateList from "screens/designStudio/TemplateList";
import { fields } from "screens/designStudio/fields";
import { DataListURLProvider } from "shared/components/dataListURL/dataListURLContext";
import { useDataListURLIds } from "shared/components/dataListURL/useDataListURLIds";
import { getAllAdIds } from "shared/components/dataListURL/utils";
import { useLayoutPreference } from "shared/hooks/useLayoutPreference";
import { Template } from "shared/types/salesEnablement";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";

const localStorageLayoutKey = "PrintOptionsLayout";

type Props = {
  onFinish: (ids: string[]) => void;
  onClose: () => void;
};

function TemplateTable({ onFinish, onClose }: Props) {
  const { selectedIds } = useDataListURLIds<Template>(getAllAdIds);
  const [layout] = useLayoutPreference(localStorageLayoutKey);

  const onConfirm = () => {
    onFinish(selectedIds);
  };

  const onCancel = () => {
    onClose();
  };

  return (
    <Drawer
      title={
        <div>
          <b>Edit Print Options:</b> Select Templates
        </div>
      }
      width="calc(100vw - 48px)"
      bodyStyle={{
        padding: 0,
        overflow: layout === "table" ? "hidden" : undefined,
        backgroundColor: "#F5F5F5",
      }}
      footerStyle={{
        zIndex: 5,
        backgroundColor: "#fff",
      }}
      visible={true}
      onClose={onCancel}
      destroyOnClose
      footer={
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button type="default" htmlType="button" onClick={onCancel}>
            Cancel
          </Button>
          <Button
            type="primary"
            onClick={onConfirm}
            disabled={selectedIds.length === 0}
          >
            Confirm
          </Button>
        </div>
      }
    >
      <TemplateList select="multiple" />
    </Drawer>
  );
}

export default function TemplateTableWrapper({ onFinish, onClose }: Props) {
  return (
    <DataListURLProvider fields={fields}>
      <QueryParamProvider adapter={ReactRouter6Adapter}>
        <TemplateTable onFinish={onFinish} onClose={onClose} />
      </QueryParamProvider>
    </DataListURLProvider>
  );
}

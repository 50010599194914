import { InboxOutlined } from "@ant-design/icons";
import { Modal, Upload, UploadProps } from "antd";
import { RcFile, UploadFile } from "antd/lib/upload/interface";
import { TemplateFile } from "shared/types/salesEnablement";
import {
  processFileUpload,
  processHtmlTemplate,
  processPdfTemplate,
  initiateIdmlParsing,
  getExecutionStatus,
} from "./UploadDragger.utils";
import { toTemplateFile } from "screens/designStudio/utils";
import { Label } from "shared/components/text/Label";
import styles from "./UploadDragger.module.scss";
import { useCallback, useRef } from "react";

const getUploadFileAttrs = (file: UploadFile) => ({
  uid: file.uid,
  name: file.name,
  fileName: encodeURIComponent(file.name),
  size: file.size,
});
type Props = {
  isEditing: boolean;
  fileList?: UploadFile<any>[];
  selectedFile?: TemplateFile;
  setSelectedFile?: (file?: TemplateFile) => void;
  setHtml?: (file?: TemplateFile) => void;
  allowDeletion: boolean;
  emptyFileError?: boolean;
};
const UploadDragger = (props: Props) => {
  const {
    selectedFile,
    setSelectedFile,
    isEditing,
    fileList,
    setHtml,
    emptyFileError,
  } = props;

  const longPollingRef = useRef<number | NodeJS.Timeout>();
  const initiate = useCallback(
    async (id: string, key: string, templateFile: TemplateFile) => {
      const { executionArn } = await initiateIdmlParsing(id, key);

      let upperLimitToCancelLongPolling = 300; // interval runs every 3 seconds. 300 times equals to 15 minutes. After 15 minutes, if parsing has not finished, we will cancel the long polling.
      longPollingRef.current = setInterval(async () => {
        if (upperLimitToCancelLongPolling <= 0) {
          clearInterval(longPollingRef.current as number);
          Modal.error({
            title: "Uploading template file timed out.",
            content: `Failed to parse IDML`,
          });
          return;
        }

        const { status, output } = await getExecutionStatus(executionArn);

        if (status === "SUCCEEDED") {
          clearInterval(longPollingRef.current as number);
          const { pages, script, template, thumbnailUrl } = JSON.parse(output);
          setSelectedFile?.({
            ...templateFile,
            id,
            type: "indesign",
            pages,
            thumbnail: thumbnailUrl,
            spMetadata: {
              entities: {
                template,
                script,
              },
            },
            status: "done",
          });
        } else if (status !== "RUNNING") {
          clearInterval(longPollingRef.current as number);
          Modal.error({
            title: "There was an error.",
            content: `Failed to parse IDML`,
          });
        }
        upperLimitToCancelLongPolling--;
      }, 3000);
    },
    [setSelectedFile],
  );
  const uploadProps: UploadProps = {
    accept: ".zip,.pdf",
    maxCount: 1,
    beforeUpload: () => Promise.reject(false), // block upload. Upload will be done in onChange.
    onChange: async info => {
      // When deleting, fileList becomes an empty array
      const { file, fileList } = info;
      const isDeleting = fileList.length === 0;

      if (file && !isDeleting) {
        const { name } = file || {};
        if (!name) return;

        const templateFile = toTemplateFile({ ...getUploadFileAttrs(file) });

        setSelectedFile?.({
          ...templateFile,
          status: "uploading",
        });

        try {
          const { id, type, bucket, filename } = await processFileUpload(
            file as RcFile,
          );
          switch (type) {
            case "indesign":
              initiate(id, `${id}/${filename}`, templateFile);
              break;
            case "html":
              const htmlResult = await processHtmlTemplate(
                id,
                bucket,
                filename,
              );
              setSelectedFile?.({
                ...templateFile,
                ...htmlResult,
                status: "done",
                type: "html",
              });

              if (htmlResult && "html" in htmlResult)
                setHtml?.({
                  html: htmlResult.html,
                  thumbnail: htmlResult.thumbnail,
                });
              break;
            case "pdf":
              const pdfResult = await processPdfTemplate(id, bucket, filename);
              setSelectedFile?.({
                ...templateFile,
                ...pdfResult,
                status: "done",
                type: "pdf",
              });
              break;
            default:
              throw new Error("Invalid file type");
          }
        } catch (err) {
          setSelectedFile?.({
            ...templateFile,
            status: "error",
          });

          Modal.error({
            title: "Error",
            content: `${err}`,
          });
        }
      }
    },
    onRemove: async () => {
      if (!selectedFile) return;

      setSelectedFile?.(undefined);
    },
    showUploadList: {
      showRemoveIcon: props.allowDeletion,
    },
  };

  return isEditing ? (
    <div className={styles.container}>
      <Label style={{ paddingBottom: 0 }}>
        {selectedFile?.status === "uploading"
          ? `Uploading file`
          : `File uploaded`}
      </Label>
      <Upload {...uploadProps} fileList={fileList} />
    </div>
  ) : (
    <div className={styles.draggerWrapper}>
      <Label required className={styles.draggerLabel}>
        Please upload your file below and customize your components:
      </Label>
      <Upload.Dragger
        {...uploadProps}
        fileList={fileList}
        className={emptyFileError ? styles.draggerError : ""}
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>

        <div>Click or drag to upload</div>
        <div>ZIP folder must contain IDML or HTML, plus all related assets</div>
      </Upload.Dragger>
      {emptyFileError ? (
        <span className={styles.draggerErrorMessage}>
          Please upload the file or click ”X” to remove this language
        </span>
      ) : null}
    </div>
  );
};
export default UploadDragger;

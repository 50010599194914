import { message } from "antd";
import { Invoice } from "shared/types/salesEnablement";

export const downloadInvoice = async (record: Invoice): Promise<void> => {
  if (record.zipFileUrl) {
    try {
      const link = document.createElement("a");
      link.href = record.zipFileUrl;
      link.download = `${record.id}.zip`;
      link.click();
      await new Promise(resolve => setTimeout(resolve, 500));
      message.success(`Download successful for Invoice ${record.id}`);
    } catch (error) {
      message.error(`Failed to download Invoice ${record.id}`);
    }
  } else {
    message.error("No zip file URL found");
  }
};

export const downloadMultipleInvoices = async (
  invoices: Invoice[],
): Promise<void> => {
  if (invoices.length === 0) {
    message.error("No invoice ID selected");
    return;
  }

  try {
    for (let i = 0; i < invoices.length; i++) {
      await downloadInvoice(invoices[i]);
    }
    message.success("All downloads completed");
  } catch (error) {
    message.error("Error downloading invoices");
  }
};

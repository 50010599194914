import { useDataListURLData } from "shared/components/dataListURL/useDataListURLData";
import { useDataListURLSorter } from "shared/components/dataListURL/useDataListURLSorter";
import { useDataListURLGlobalFilter } from "shared/components/dataListURL/useDataListURLGlobalFilter";
import { DataListURLProvider } from "shared/components/dataListURL/dataListURLContext";
import ToolbarTable, {
  ToolbarButton,
} from "shared/components/toolbarTable/ToolbarTable";
import { DataListTagsURL } from "shared/components/dataListURL/DataListTagsURL";
import styles from "./Archive.module.scss";
import {
  ArchiveItem,
  PrintArchiveTableKeys,
  UpdateArchiveOrderStatusPayload,
} from "shared/types/salesEnablementArchive";
import { DataProvider } from "./hooks/SalesEnablementDataProvider";
import { Outlet, useParams } from "react-router-dom";
import useNavigateWithSearch from "shared/hooks/useNavigateWithSearch";
import { getColumns, PrintArchiveColumn } from "./archive/printArchive/columns";
import DataListURLTable from "shared/components/dataListURL/DataListURLTable";
import {
  printArchiveFieldKeys,
  printArchiveFields,
} from "./archive/printArchive/fields";
import { useWindowSize } from "shared/hooks/useWindowSize";
import { PrintCheckout } from "screens/adLibrary/marketingMaterials/printDrawer/PrintCheckout";
import { useForm } from "antd/lib/form/Form";
import { Form } from "antd";
import { useFetchPrintArchives } from "shared/hooks/salesEnablement/useFetchPrintArchives";
import { TableActionsContextProvider } from "shared/components/HoverActionsVirtualTable/TableActionsContext";
import { ContextTableActions } from "shared/components/tableActions/ContextTableActions";
import { InlineTableActions } from "shared/components/tableActions/InlineTableActions";
import { setupEditableCells } from "shared/components/dataList/setupEditableCells";
import { useUpdateArchiveOrderStatus } from "shared/hooks/salesEnablement/useUpdateArchiveOrderStatus";
import { CheckCircleOutlined } from "@ant-design/icons";
import { ROUTES } from "./archive/constants";

const PrintArchiveTableContainer = () => {
  const [form] = useForm();
  const { windowInnerHeight, windowInnerWidth } = useWindowSize();

  const { mutate: updateArchiveOrderStatus } = useUpdateArchiveOrderStatus();

  const { data: printArchives = [], isLoading } = useFetchPrintArchives();

  const navigate = useNavigateWithSearch();

  const { archiveId } = useParams();

  const openOrderDetails = async (order: ArchiveItem) => {
    const updatedFormData = {
      shippingData: order.materialPrintOrderData?.shippingData,
      materials: {
        [order.marketingMaterial?.id ?? ""]: order.materialPrintOrderData,
      },
      totalAmount: order.materialPrintOrderData?.amount ?? 0,
      totalWeight: 0,
    };
    form.setFieldsValue(updatedFormData);
    navigate(ROUTES.printOrderDetails(order.id));
  };

  const handleSave = (record: ArchiveItem) => {
    const { id, orderStatus } = record;
    updateArchiveOrderStatus([
      {
        id,
        orderStatus: orderStatus ?? "IN_PROGRESS",
      },
    ]);
  };

  const { columns, components } = setupEditableCells<
    ArchiveItem,
    PrintArchiveColumn
  >({
    columns: getColumns(openOrderDetails),
    handleSave,
  });

  const { data, selectedItems } = useDataListURLData<
    PrintArchiveTableKeys,
    ArchiveItem
  >({
    isError: false,
    isLoading,
    data: printArchives,
  });

  const { sortKey, sortOrder, sortItems } = useDataListURLSorter<
    PrintArchiveTableKeys,
    ArchiveItem
  >();
  const { globalFilter, setGlobalFilter } = useDataListURLGlobalFilter();

  const generateToolbarContents: (
    record?: ArchiveItem,
  ) => ToolbarButton = record => {
    if (!record) return {};
    return {
      CustomButton: {
        icon: <CheckCircleOutlined />,
        onClick: () => {
          handleSave({ ...record, orderStatus: "COMPLETE" });
        },
      },
    };
  };

  const toolbarContents: ToolbarButton = {
    CustomButton: {
      disabled: selectedItems.length === 0,
      icon: <CheckCircleOutlined />,
      extraInfo: {
        text: "Mark as Complete",
        tooltipText: "Mark as Complete",
        icon: <CheckCircleOutlined />,
      },
      onClick: () => {
        const archives: UpdateArchiveOrderStatusPayload[] = selectedItems.map(
          ({ id }) => ({
            id,
            orderStatus: "COMPLETE",
          }),
        );
        updateArchiveOrderStatus(archives);
      },
    },
  };

  return (
    <div className={styles.container}>
      <ToolbarTable
        titleTooltip="Search"
        toolbarContents={toolbarContents}
        searchPlaceholder="Search"
        onSearch={setGlobalFilter}
        searchValue={globalFilter ?? undefined}
        disableSearch={false}
        previewFormat={false}
        sortingOrder={sortKey && sortOrder ? [sortKey, sortOrder] : undefined}
        onSortChange={([columnKey, order]) => {
          sortItems(columnKey, order);
        }}
        includeFilterOnTableLayout={true}
      />
      <DataListTagsURL<PrintArchiveTableKeys, ArchiveItem>
        data={data}
        originalData={printArchives}
      />
      <TableActionsContextProvider<ArchiveItem>
        renderContextActions={(record?: ArchiveItem) => (
          <ContextTableActions
            getItemsToRender={generateToolbarContents}
            record={record}
          />
        )}
        renderHoverActions={record => (
          <InlineTableActions
            getItemsToRender={generateToolbarContents}
            record={record}
          />
        )}
      >
        <DataListURLTable<PrintArchiveTableKeys, ArchiveItem>
          type="hoverActionsVirtualTable"
          loading={isLoading}
          dataSource={data}
          components={components}
          columns={columns}
          pagination={false}
          rowKey="id"
          size="small"
          scroll={{ y: windowInnerHeight - 265, x: windowInnerWidth - 20 }}
          fieldKeys={printArchiveFieldKeys}
        />
        <Outlet />

        <Form form={form} layout="horizontal" labelAlign="left">
          {archiveId && <PrintCheckout form={form} />}
        </Form>
      </TableActionsContextProvider>
    </div>
  );
};
const PrintArchive = () => {
  return (
    <DataProvider>
      <DataListURLProvider fields={printArchiveFields}>
        <PrintArchiveTableContainer />
      </DataListURLProvider>
    </DataProvider>
  );
};

export default PrintArchive;

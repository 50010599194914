import { Button, Row } from "antd";

type Props = {
  formId: string;
  isLoading?: boolean;
  onDelete?: () => void;
  onCancel: () => void;
};

export const DrawerFooter = ({
  formId,
  isLoading,
  onDelete,
  onCancel,
}: Props) => {
  return (
    <Row justify="end" gutter={16}>
      {onDelete && (
        <Button danger onClick={onDelete}>
          Delete
        </Button>
      )}
      <Button style={{ marginRight: 8 }} onClick={onCancel}>
        Cancel
      </Button>
      <Button
        type="primary"
        form={formId}
        htmlType="submit"
        loading={isLoading}
      >
        Save
      </Button>
    </Row>
  );
};

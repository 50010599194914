import { Col, Form, InputNumber, Row, Typography } from "antd";
import { PrintOptionsRangeQuantity } from "shared/types/printOptions";
import styles from "./MinMaxInput.module.scss";

type Props = {
  fieldName: (string | number)[];
  value?: PrintOptionsRangeQuantity;
  minThreshold?: number;
  maxThreshold?: number;
};

export const MinMaxInput = ({
  fieldName,
  minThreshold,
  maxThreshold,
}: Props) => {
  const validateMax = (value: number) => {
    if (maxThreshold && value !== maxThreshold - 1) {
      return Promise.reject();
    }
    return Promise.resolve();
  };

  const validateMin = (value: number) => {
    if (minThreshold && value !== minThreshold + 1) {
      return Promise.reject();
    }
    return Promise.resolve();
  };
  return (
    <Row align="middle" justify="space-between" gutter={8}>
      <Col span={11}>
        <Form.Item
          className={styles.formItem}
          name={[...fieldName, "min"]}
          rules={[{ validator: (_, value) => validateMin(value) }]}
        >
          <InputNumber placeholder="Min" />
        </Form.Item>
      </Col>
      <Typography.Text>to</Typography.Text>
      <Col span={11}>
        <Form.Item
          className={styles.formItem}
          name={[...fieldName, "max"]}
          rules={[{ validator: (_, value) => validateMax(value) }]}
        >
          <InputNumber placeholder="Max" />
        </Form.Item>
      </Col>
    </Row>
  );
};

import { MarketingMaterial } from "shared/types/marketingMaterials";
import { Template } from "shared/types/salesEnablement";
import { Typography, Form } from "antd";
import { EmailDeliveryForm } from "./EmailDrawerForm";
import Preview from "screens/adLibrary/marketingMaterialDrawer/Preview";
import TemplateRenderDataProvider from "screens/designStudio/hooks/TemplateRenderDataProvider";
import { FormInstance } from "antd/es/form/Form";
import { useUser } from "shared/hooks/useUser";
import styles from "./EmailDrawerContent.module.scss";

type Props = {
  template: Template;
  material: MarketingMaterial;
  form: FormInstance<any>;
};

export const EmailDrawerContent = ({ template, material, form }: Props) => {
  const { email: from } = useUser();

  return (
    <div className={styles.container}>
      <Form
        className={styles.form}
        form={form}
        initialValues={{
          [material.id]: {
            from,
            subject: template.defaultEmailSubject?.[material.language] ?? "",
          },
        }}
        requiredMark={false}
      >
        <EmailDeliveryForm id={material.id} />
      </Form>
      <div className={styles.section}>
        <Typography.Text>
          The recipients will receive the email as shown below.
        </Typography.Text>
        <TemplateRenderDataProvider
          key={template.id}
          file={template.files[material.language]}
          material={material}
        >
          <Preview selectedFile={template.files[material.language]} />
        </TemplateRenderDataProvider>
      </div>
    </div>
  );
};

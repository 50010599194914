import { useMemo, useState } from "react";
import { Drawer } from "antd";
import { Steps } from "./Steps";
import { Footer } from "./Footer";
import styles from "./ExportDrawer.module.scss";
import { ExportDestination } from "./types";
import { MatchColumns } from "../adLibrary/MatchColumns";
import { FillAdInfo } from "../adLibrary/FillAdInfo";
import { ExportSettings } from "./ExportSettings";
import { PortalConfiguration } from "../portal/PortalConfiguration";
import { useAdLibraryExportContext } from "../adLibrary/AdLibraryExportContext";
import { MapData } from "../offPlatform/MapData";
import { IFilter } from "shared/hooks/assetExporter/useFeedConfigFetch";

interface ExportDrawerProps {
  onClose: () => void;
  open?: boolean;
  filterArgs?: IFilter[];
  searchValue: string;
}

export const ExportDrawer = ({
  onClose,
  open,
  filterArgs,
  searchValue,
}: ExportDrawerProps) => {
  const [currentStep, setCurrentStep] = useState(0);

  const {
    setAdType,
    setColumnMatches,
    setAdInfoUpdates,
    exportDestination,
    setExportDestination,
  } = useAdLibraryExportContext();

  const handleClose = () => {
    setAdType(null);
    setCurrentStep(0);
    setExportDestination(null);
    setColumnMatches({});
    setAdInfoUpdates({});

    onClose();
  };

  const CurrentStepComponent = useMemo(() => {
    const steps = {
      [ExportDestination.CSVDownload]: [],
      [ExportDestination.AdLibrary]: [MatchColumns, FillAdInfo],
      [ExportDestination.GoogleSpreadsheet]: [MapData],
      [ExportDestination.Portal]: [PortalConfiguration],
    };
    const exportDestinationSteps = exportDestination
      ? steps[exportDestination]
      : [];
    return [ExportSettings, ...exportDestinationSteps][currentStep];
  }, [currentStep, exportDestination]);

  return (
    <Drawer
      visible={open}
      title={<Steps currentStep={currentStep} />}
      width="calc(100vw - 48px)"
      footer={
        <Footer
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          onClose={handleClose}
          filterArgs={filterArgs}
          searchValue={searchValue}
        />
      }
      className={styles.drawer}
      onClose={handleClose}
      closable={false}
      destroyOnClose
    >
      <CurrentStepComponent />
    </Drawer>
  );
};

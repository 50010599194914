import { DataListURLProvider } from "shared/components/dataListURL/dataListURLContext";
import { DataProvider } from "../designStudio/hooks/SalesEnablementDataProvider";
import { InvoiceTable } from "./InvoiceTable/Table";
import InvoiceSummaryDrawer from "./InvoiceDrawer/SummaryDrawer";

import { fields } from "./fields";

const InvoiceDashboard = () => {
  return (
    <DataProvider>
      <DataListURLProvider fields={fields}>
        <InvoiceTable />
        <InvoiceSummaryDrawer />
      </DataListURLProvider>
    </DataProvider>
  );
};

export default InvoiceDashboard;

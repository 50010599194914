import { Button, Tooltip } from "antd";
import styles from "./SummaryDrawerFooter.module.scss";
import { DownloadOutlined, MailOutlined } from "@ant-design/icons";

type InvoiceSummaryFooterProps = {
  onSendEmail: () => void;
  onDownload: () => void;
  onClose: () => void;
};

const InvoiceSummaryFooter = ({
  onSendEmail,
  onDownload,
  onClose,
}: InvoiceSummaryFooterProps) => {
  return (
    <div className={styles.footer}>
      <div>
        {onSendEmail && (
          <Tooltip title="Email">
            <Button type="default" htmlType="button" onClick={onSendEmail}>
              <MailOutlined />
              Email
            </Button>
          </Tooltip>
        )}
        {onDownload && (
          <Button
            disabled={false}
            type="default"
            htmlType="button"
            onClick={onDownload}
          >
            <DownloadOutlined />
            Download
          </Button>
        )}
      </div>

      <div>
        <Button type="primary" htmlType="button" onClick={onClose}>
          Close
        </Button>
      </div>
    </div>
  );
};

export default InvoiceSummaryFooter;

import {
  DeleteOutlined,
  ExclamationCircleFilled,
  PlusOutlined,
} from "@ant-design/icons";
import { Alert, Button, Col, Row, Table, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useMemo, useState } from "react";
import { useFetchTemplates } from "shared/hooks/designStudio/useFetchTemplates";
import { Template } from "shared/types/salesEnablement";
import { useQueryParams } from "use-query-params";
import TemplateTableWrapper from "./associatedTemplates/TemplateTable";
import { uniq } from "lodash";

import { PreviewTemplate } from "shared/components/salesEnablement/template/PreviewTemplate";

type Props = {
  printOptionId?: string;
  value?: string[];
  onChange?: (associatedTemplates: string[]) => void;
};

export const AssociatedTemplates = ({
  printOptionId,
  value: associatedTemplates,
  onChange,
}: Props) => {
  const [templatePreview, setTemplatePreview] = useState<string>();
  const [, setQuery] = useQueryParams();
  const { templates: allTemplates } = useFetchTemplates();
  const templatesWithPrintOption: Record<string, Template> = useMemo(() => {
    return allTemplates.reduce((acc, template) => {
      if (template.printOptionId) {
        return {
          ...acc,
          [template.id]: template,
        };
      }
      return acc;
    }, {});
  }, [allTemplates]);

  const dataSource = associatedTemplates
    ? associatedTemplates.flatMap(templateId => {
        const template = templatesWithPrintOption[templateId];
        if (!template) return [];
        return {
          id: templateId,
          name: template.name,
        };
      })
    : [];

  const columns: ColumnsType<{
    id: string;
    name: string;
  }> = [
    {
      title: "Template Name",
      dataIndex: "name",
      width: "95%",
      render: (_, record) => {
        const templatePrintOption =
          templatesWithPrintOption[record.id]?.printOptionId;
        const alreadyAssociated =
          templatePrintOption && templatePrintOption !== printOptionId;
        return (
          <Row justify="space-between">
            <Typography.Link onClick={() => setTemplatePreview(record.id)}>
              {record.name}
            </Typography.Link>
            {alreadyAssociated && (
              <Col>
                <ExclamationCircleFilled
                  style={{
                    color: "orange",
                    marginRight: 4,
                  }}
                />
                <Typography.Text style={{ fontSize: 13 }}>
                  Already Contains print configuration
                </Typography.Text>
              </Col>
            )}
          </Row>
        );
      },
    },
    {
      title: "",
      align: "right",
      render: (_, record) => (
        <DeleteOutlined
          onClick={() => {
            if (associatedTemplates)
              onChange?.(associatedTemplates.filter(id => id !== record.id));
          }}
        />
      ),
    },
  ];

  const [tableMode, setTableMode] = useState<"add" | "view">("view");

  const onFinish = (ids: string[]) => {
    onChange?.(uniq([...(associatedTemplates ?? []), ...ids]));
    onClose();
  };

  const onClose = () => {
    setQuery({ deliveryMethods: undefined }, "replaceIn");
    setTableMode("view");
  };

  const handleAddRow = () => {
    setTableMode("add");
    setQuery({ deliveryMethods: "print" }, "replaceIn");
  };

  const hasTemplateConfiguration = useMemo(() => {
    return associatedTemplates?.some(templateId => {
      const templatePrintOption =
        templatesWithPrintOption[templateId]?.printOptionId;
      return templatePrintOption && templatePrintOption !== printOptionId;
    });
  }, [associatedTemplates, printOptionId, templatesWithPrintOption]);

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        {hasTemplateConfiguration && (
          <Alert
            showIcon
            message="Some of the templates are associated with other print options. If you save they will be associated with this one instead."
            type="warning"
          />
        )}
      </Col>
      <Col span={24}>
        <Table
          dataSource={dataSource}
          columns={columns}
          pagination={false}
          locale={{ emptyText: "No associated templates" }}
          footer={() => (
            <Button type="link" onClick={handleAddRow} icon={<PlusOutlined />}>
              Select Templates
            </Button>
          )}
        />
        {tableMode === "add" && (
          <TemplateTableWrapper onFinish={onFinish} onClose={onClose} />
        )}
      </Col>
      <PreviewTemplate
        visible={!!templatePreview}
        onClose={() => setTemplatePreview(undefined)}
        templateData={templatesWithPrintOption[templatePreview ?? ""]}
      />
    </Row>
  );
};

import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  ClockCircleOutlined,
  CloudDownloadOutlined,
  ColumnHeightOutlined,
  ControlOutlined,
  CopyOutlined,
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  ExclamationCircleFilled,
  ExportOutlined,
  EyeOutlined,
  FileAddOutlined,
  FileDoneOutlined,
  FilePdfOutlined,
  FileZipOutlined,
  FilterOutlined,
  HistoryOutlined,
  ImportOutlined,
  InboxOutlined,
  LeftOutlined,
  NotificationOutlined,
  PlusOutlined,
  ReloadOutlined,
  SettingOutlined,
  ShareAltOutlined,
  SwapOutlined,
  UploadOutlined,
  WarningFilled,
} from "@ant-design/icons";
import {
  Button,
  ButtonProps,
  Cascader,
  Dropdown,
  Switch,
  Tooltip,
  TooltipProps,
} from "antd";
import { Key, PropsWithChildren } from "react";
import IntermediateSwitch from "shared/button/IntermediateSwitch";
import { SelectWithSort } from "shared/components/SelectWithSort/SelectWithSort";
import MetaLogoIcon from "shared/components/MediaLogos/MetaLogo";
import ColumnsOutlined from "shared/icons/ColumnsOutlined";
import { ClientType } from "shared/types/adLibrary";
import { IndustryType } from "shared/types/shared";
import { ToolbarButtonKey, ToolbarButtonValue } from "shared/types/toolbar";
import { isPharma } from "utils/helpers";
import "./ToolbarTable.scss";
import PreviewFormatButton from "screens/campaignManagement/adReview/PreviewFormatButton";
import { AdFormat } from "screens/adLibrary/facebookUtils/types";
import PublishUnpublishIcon from "./PublishUnpublishIcon";
import { Print } from "./toolbarButtonSwitch/Print";
import { SwitchChangeEventHandler } from "antd/lib/switch";
import { TemplateStatus } from "shared/types/salesEnablement";

type ToolbarButtonProps = {
  buttonKey: ToolbarButtonKey;
  sortingOrder?: string[];
  target: ToolbarButtonValue | undefined;
  clientType?: IndustryType;
  onSortChange?: (value: Key[]) => void;
  tooltipProps?: Partial<TooltipProps>;
};

export const ToolbarButtonSwitch = ({
  buttonKey,
  clientType,
  sortingOrder,
  onSortChange,
  target,
  tooltipProps,
}: ToolbarButtonProps) => {
  if (!target) return null;
  const disabled = target["disabled"];
  const onClick = target["onClick"];
  const extraInfo = target["extraInfo"];
  const display = target["display"];
  const ButtonType = extraInfo?.FULL ? undefined : "primary";
  const iconOnly = extraInfo?.iconOnly;
  const checked = target["checked"];
  const loading = target["loading"];
  const type = target["type"] || "default";
  const buttonShape = extraInfo?.buttonShape;
  const tooltipPropsOverride = tooltipProps ?? {};

  const buttonTextInline = (text: string) => {
    if (type === "inline") return null;
    return text;
  };

  switch (buttonKey) {
    case "New": {
      const buttonText = extraInfo?.text ?? buttonKey;
      const tooltipText = extraInfo?.tooltip ?? "Create new";
      return display === undefined || !!display ? (
        <Tooltip title={tooltipText} {...tooltipPropsOverride}>
          <NewButton
            dropdownMenu={extraInfo?.dropdownMenu}
            data-cy="new-toolbar-button"
            key="new-toolbar-button"
            type={ButtonType}
            style={{ ...extraInfo?.style, borderRadius: "15px" }}
            disabled={disabled}
            onClick={onClick}
            className="action-btn-toolbar"
            icon={<PlusOutlined />}
          >
            {buttonText}
          </NewButton>
        </Tooltip>
      ) : null;
    }
    case "Edit":
      return (
        <Tooltip title={"Edit"} {...tooltipPropsOverride}>
          <Button
            data-cy="edit-toolbar-button"
            key="edit-toolbar-button"
            disabled={disabled}
            onClick={onClick}
            className="action-btn-toolbar"
            icon={<EditOutlined />}
            shape="circle"
          />
        </Tooltip>
      );
    case "Configure":
      return (
        <Tooltip title={"Configure"} {...tooltipPropsOverride}>
          <Button
            data-cy="configure-button"
            key="configure-button"
            disabled={disabled}
            onClick={onClick}
            shape={buttonShape}
            className="action-btn-toolbar"
            icon={<SettingOutlined />}
          >
            {iconOnly ? "" : buttonKey}
          </Button>
        </Tooltip>
      );
    case "PullLatest":
      return (
        <Tooltip title={"Pull Latest"} {...tooltipPropsOverride}>
          <Button
            data-cy="pull-latest-button"
            key="pull-latest-button"
            disabled={disabled}
            onClick={onClick}
            className="action-btn-toolbar"
            shape={buttonShape}
            icon={<CloudDownloadOutlined />}
          >
            {iconOnly ? "" : "Pull Latest"}
          </Button>
        </Tooltip>
      );
    case "Duplicate": {
      const tooltipText = extraInfo?.tooltip ?? "Duplicate";
      return (
        <Tooltip title={tooltipText ?? "Duplicate"} {...tooltipPropsOverride}>
          <Button
            data-cy="duplicate-toolbar-button"
            key="duplicate-toolbar-button"
            disabled={disabled}
            onClick={onClick}
            className="action-btn-toolbar"
            icon={<CopyOutlined />}
            shape="circle"
          />
        </Tooltip>
      );
    }
    case "Delete": {
      const deleteTooltipText = extraInfo?.tooltip ?? "Delete";
      return (
        <Tooltip title={deleteTooltipText} {...tooltipPropsOverride}>
          <Button
            data-cy="delete-toolbar-button"
            key="delete-toolbar-button"
            disabled={disabled}
            onClick={onClick}
            className="action-btn-toolbar"
            icon={extraInfo?.icon ?? <DeleteOutlined />}
            shape="circle"
            danger
          />
        </Tooltip>
      );
    }
    case "Toggle":
      return (
        <Tooltip
          title={"Enable/Disable selected rows"}
          {...tooltipPropsOverride}
        >
          <Button
            data-cy="toggle-toolbar-button"
            key="toggle-toolbar-button"
            disabled={disabled}
            onClick={onClick}
            className="action-btn-toolbar"
            icon={<SwapOutlined />}
          />
        </Tooltip>
      );

    case "Import":
      return (
        <Tooltip title={"Upload a CSV"} {...tooltipPropsOverride}>
          <Button
            data-cy="import-button"
            key="import-button"
            disabled={disabled}
            onClick={onClick}
            className="action-btn-toolbar"
            icon={<ImportOutlined />}
          >
            {buttonKey}
          </Button>
        </Tooltip>
      );
    case "Preview":
      return (
        <Tooltip
          title={extraInfo?.tooltip || "Preview Ad"}
          {...tooltipPropsOverride}
        >
          <Button
            data-cy="preview-toolbar-button"
            key="preview-toolbar-button"
            disabled={disabled}
            onClick={onClick}
            className="action-btn-toolbar"
            icon={<EyeOutlined />}
          >
            {buttonKey}
          </Button>
        </Tooltip>
      );
    case "Load":
      return (
        <Tooltip
          {...tooltipPropsOverride}
          title={
            extraInfo?.tooltip ??
            "Load selected ads to Facebook (Approved only)"
          }
        >
          <Button
            data-cy="load-toolbar-button"
            key="load-toolbar-button"
            disabled={disabled}
            onClick={onClick}
            className="action-btn-toolbar"
            icon={target.icon ?? <FileDoneOutlined />}
          >
            {buttonTextInline(extraInfo?.title ?? "Load to FB")}
          </Button>
        </Tooltip>
      );
    case "LoadV2":
      return (
        <Tooltip
          title={
            extraInfo?.tooltip ?? "Load selected ads to Meta (Approved only)"
          }
        >
          <Button
            data-cy="load-v2-toolbar-button"
            key="load-v2-toolbar-button"
            disabled={disabled}
            loading={loading}
            onClick={onClick}
            className="action-btn-toolbar"
            icon={<MetaLogoIcon />}
          >
            {buttonTextInline(extraInfo?.title ?? "Load to Meta")}
          </Button>
        </Tooltip>
      );
    case "Params":
      return (
        <Tooltip title={"Edit Parameters"} {...tooltipPropsOverride}>
          <Button
            disabled={disabled}
            data-cy="column-toolbar-button"
            key="column-toolbar-button"
            onClick={onClick}
            className="action-btn-toolbar"
            icon={<ControlOutlined style={{ opacity: disabled ? 0.3 : 1 }} />}
          >
            {buttonTextInline("Edit parameters")}
          </Button>
        </Tooltip>
      );
    case "History":
      return (
        <Tooltip title={"View  session history"} {...tooltipPropsOverride}>
          <Button
            data-cy="history-button"
            key="history-button"
            disabled={disabled}
            onClick={onClick}
            className="action-btn-toolbar"
            icon={<HistoryOutlined />}
          >
            View History
          </Button>
        </Tooltip>
      );
    case "Tooltip":
      return (
        <Tooltip title={extraInfo.title} {...tooltipPropsOverride}>
          <Button
            data-cy="load-toolbar-button"
            key="load-toolbar-button"
            disabled={disabled}
            onClick={onClick}
            className="action-btn-toolbar"
            icon={<WarningFilled style={{ color: "rgba(250, 173, 20, 1)" }} />}
          >
            {`${extraInfo.value} Issues`}
          </Button>
        </Tooltip>
      );
    case "Warning":
    case "Error":
      return (
        <Tooltip
          title={"Density setting: Compact / Medium"}
          {...tooltipPropsOverride}
        >
          <Button
            disabled={disabled}
            data-cy="expand-toolbar-button"
            key="expand-toolbar-button"
            onClick={onClick}
            className="action-btn-toolbar"
            icon={
              buttonKey === "Warning" ? (
                <WarningFilled style={{ color: "rgba(250, 173, 20, 1)" }} />
              ) : (
                <ExclamationCircleFilled style={{ color: "#FF4D4F" }} />
              )
            }
          />
        </Tooltip>
      );
    case "Expand":
      return (
        <Tooltip
          title={"Density setting: Compact / Medium"}
          {...tooltipPropsOverride}
        >
          <Button
            disabled={disabled}
            data-cy="expand-toolbar-button"
            key="expand-toolbar-button"
            onClick={onClick}
            className="action-btn-toolbar"
            icon={<ColumnHeightOutlined />}
          />
        </Tooltip>
      );
    case "Column":
      return (
        <Tooltip title={"Select columns to display"} {...tooltipPropsOverride}>
          <Button
            disabled={disabled}
            data-cy="column-toolbar-button"
            key="column-toolbar-button"
            onClick={onClick}
            className="action-btn-toolbar"
            icon={<ColumnsOutlined style={{ opacity: disabled ? 0.3 : 1 }} />}
          >
            {extraInfo?.iconOnly ? "" : ClientType[clientType || "auto"]}
          </Button>
        </Tooltip>
      );
    case "Filter":
      return (
        <Tooltip title={"Filter"} {...tooltipPropsOverride}>
          <Button
            data-cy="filter-toolbar-button"
            key="filter-toolbar-button"
            disabled={disabled}
            onClick={onClick}
            className="action-btn-toolbar"
            icon={<FilterOutlined />}
          />
        </Tooltip>
      );

    case "Sort":
      return (
        <Tooltip title={"Sort By"} {...tooltipPropsOverride}>
          <Cascader
            data-cy="sort-toolbar-button"
            key="sort-toolbar-button"
            disabled={disabled}
            className="action-btn-toolbar"
            displayRender={label =>
              renderSortBy(label as string[], sortingOrder?.[1])
            }
            style={{ width: 190 }}
            options={extraInfo}
            defaultValue={sortingOrder}
            onChange={onSortChange}
            allowClear={false}
          />
        </Tooltip>
      );

    case "SelectWithSort":
      return (
        <SelectWithSort
          extraInfo={extraInfo}
          sortingOrder={sortingOrder}
          disabled={disabled}
          onSortChange={onSortChange}
        />
      );

    case "BuildAsset":
      return !!display ? (
        <Button
          data-cy="build-button"
          key="build-button"
          disabled={disabled}
          onClick={onClick}
          className="action-btn-toolbar"
        >
          Build Asset
        </Button>
      ) : null;
    case "Review":
      return !!display ? (
        <Button
          data-cy="review-button"
          key="review-button"
          disabled={disabled}
          onClick={onClick}
          className="action-btn-toolbar"
        >
          {buttonKey}
        </Button>
      ) : null;
    case "SaveOrder":
      return !!display ? (
        <Button
          data-cy="save-order-button"
          key="save-order-button"
          disabled={disabled}
          onClick={onClick}
          className="action-btn-toolbar"
        >
          Save Order Progress
        </Button>
      ) : null;
    case "CreateOffer":
      return !!display ? (
        <Button
          data-cy="save-order-button"
          key="save-order-button"
          disabled={disabled}
          onClick={onClick}
          className="action-btn-toolbar"
        >
          Create Offer
        </Button>
      ) : null;
    case "UpdateOffer":
      return !!display ? (
        <Button
          data-cy="save-order-button"
          key="save-order-button"
          disabled={disabled}
          onClick={onClick}
          className="action-btn-toolbar"
        >
          Update Offer
        </Button>
      ) : null;
    case "OrderDetails":
      return (
        <Button
          data-cy="order-details-button"
          key="order-details-button"
          disabled={disabled}
          onClick={onClick}
          className="action-btn-toolbar"
        >
          Order Details
        </Button>
      );
    case "Export":
      return !!display ? (
        <Tooltip
          title={extraInfo?.tooltipText || "Export"}
          {...tooltipPropsOverride}
        >
          <Button
            data-cy="export-button"
            key="export-button"
            disabled={disabled}
            loading={loading}
            onClick={onClick}
            className="action-btn-toolbar"
            icon={<ExportOutlined />}
          >
            {buttonTextInline(buttonKey)}
          </Button>
        </Tooltip>
      ) : null;
    case "Coop":
      return !!display ? (
        <Button
          data-cy="coop-button"
          key="coop-button"
          disabled={disabled}
          onClick={onClick}
          className="action-btn-toolbar"
        >
          {buttonKey}
        </Button>
      ) : null;
    case "Launch":
      return !!display ? (
        <Button
          data-cy="launch-button"
          key="launch-button"
          disabled={disabled}
          onClick={onClick}
          className="action-btn-toolbar"
        >
          {buttonKey}
        </Button>
      ) : null;
    case "Status":
      return !!display ? (
        <Button
          data-cy="rocket-status-button"
          key="status-button"
          disabled={disabled}
          onClick={onClick}
          className="action-btn-toolbar"
        >
          {buttonKey}
        </Button>
      ) : null;
    case "Launcher":
      return !!display ? (
        <Button
          key="launcher-button"
          disabled={disabled}
          onClick={onClick}
          className="action-btn-toolbar"
        >
          Launch
        </Button>
      ) : null;
    case "SelectOffer":
      return !!display ? (
        <Button
          key="launcher-button"
          disabled={disabled}
          onClick={onClick}
          className="action-btn-toolbar"
        >
          {isPharma ? "Select Module" : "Select Offer"}
        </Button>
      ) : null;
    case "LoadEA":
      return (
        <Tooltip title={"Load Everything Ads"} {...tooltipPropsOverride}>
          <Button
            data-cy="load-ea-toolbar-button"
            key="load-ea-toolbar-button"
            disabled={disabled}
            onClick={onClick}
            className="action-btn-toolbar"
            icon={<UploadOutlined />}
          >
            Load
          </Button>
        </Tooltip>
      );
    case "SelectOfferArrow":
      return !!display ? (
        <Button
          key="launcher-button"
          icon={<LeftOutlined />}
          type="text"
          disabled={disabled}
          onClick={onClick}
          className="action-btn-toolbar"
        >
          Select Offer
        </Button>
      ) : null;
    case "OrderPage":
      return !!display ? (
        <Button
          key="launcher-button"
          icon={<LeftOutlined />}
          type="text"
          disabled={disabled}
          onClick={onClick}
          className="action-btn-toolbar"
        >
          Orders
        </Button>
      ) : null;
    case "ActiveAll":
      return !!display ? (
        <div className="action-switch-toolbar">
          <Switch
            disabled={disabled}
            onClick={onClick as SwitchChangeEventHandler}
            checked={checked}
            loading={loading}
          />
          <span className={disabled ? "disabled-text" : "enabled-text"}>
            Enable/Disable
          </span>
        </div>
      ) : null;
    case "ActiveSome":
      return !!display ? (
        <div className="action-switch-toolbar">
          <IntermediateSwitch disabled={disabled} onClick={onClick} />
          <span className={disabled ? "disabled-text" : "enabled-text"}>
            Enable/Disable
          </span>
        </div>
      ) : null;
    case "ViewAssetBatches":
      return !!display ? (
        <Button
          data-cy="view-asset-batches-button"
          key="view-asset-batches-button"
          disabled={disabled}
          onClick={onClick}
          className="action-btn-toolbar"
          icon={<ControlOutlined />}
        >
          View Asset Batches
        </Button>
      ) : null;
    case "GenerateUrl":
      return (
        <Button
          data-cy="generate-url-button"
          key="generate-url-button"
          disabled={disabled}
          onClick={onClick}
          className="action-btn-toolbar"
          icon={<ShareAltOutlined />}
        >
          Generate URL
        </Button>
      );
    case "Archive":
      return (
        <Button
          data-cy="archive-button"
          key="archive-button"
          disabled={disabled}
          onClick={onClick}
          className="action-btn-toolbar"
          icon={<InboxOutlined />}
        >
          Archive
        </Button>
      );
    case "Versions":
      return (
        <Tooltip title={"Versions"} {...tooltipPropsOverride}>
          <Button
            data-cy="versions-button"
            key="versions-button"
            disabled={disabled}
            onClick={onClick}
            className="action-btn-toolbar"
            shape={buttonShape}
            icon={<ClockCircleOutlined />}
          >
            {iconOnly ? "" : buttonKey}
          </Button>
        </Tooltip>
      );
    case "DownloadCSV":
      return (
        <Tooltip title={"Download CSV"} {...tooltipPropsOverride}>
          <Button
            data-cy="download-csv-button"
            key="download-csv-button"
            disabled={disabled}
            onClick={onClick}
            className="action-btn-toolbar"
            shape={buttonShape}
            icon={<DownloadOutlined />}
          >
            {iconOnly ? "" : "Download CSV"}
          </Button>
        </Tooltip>
      );
    case "NewInstance":
      return (
        <Tooltip title="New Instance">
          <Button
            key="new-instance-toolbar-button"
            disabled={disabled}
            onClick={onClick}
            className="action-btn-toolbar"
            shape={type == "inline" ? "circle" : undefined}
            icon={<FileAddOutlined />}
          >
            {buttonTextInline("New instance")}
          </Button>
        </Tooltip>
      );
    case "CustomButton":
      return (
        <Tooltip title={extraInfo.tooltipText}>
          <Button
            data-cy={extraInfo.dataCy}
            key={extraInfo.key}
            disabled={disabled}
            onClick={onClick}
            className="action-btn-toolbar"
            shape={type == "inline" ? "circle" : undefined}
            icon={extraInfo.icon || <FileAddOutlined />}
          >
            {buttonTextInline(extraInfo.text)}
          </Button>
        </Tooltip>
      );
    case "PreviewFormat":
      return (
        <div>
          <PreviewFormatButton
            key="mobile"
            format={AdFormat.MOBILE_FEED_STANDARD}
          />
          <PreviewFormatButton
            key="desktop"
            format={AdFormat.DESKTOP_FEED_STANDARD}
          />
        </div>
      );
    case "Reset":
      return (
        <Button
          key="reset-instance-button"
          disabled={disabled}
          onClick={onClick}
          className="action-btn-toolbar"
          shape={type == "inline" ? "circle" : undefined}
          icon={<ReloadOutlined />}
        >
          Reset Load Status
        </Button>
      );
    case "DownloadPDF":
      return (
        <Tooltip
          title={extraInfo.tooltipText || "PDF"}
          {...tooltipPropsOverride}
        >
          <Button
            key="download-pdf-button"
            disabled={disabled}
            onClick={onClick}
            className="action-btn-toolbar"
            shape={type == "inline" ? "circle" : undefined}
            icon={extraInfo?.icon ?? <FilePdfOutlined />}
          >
            {buttonTextInline(extraInfo?.title ?? "Download PDF")}
          </Button>
        </Tooltip>
      );
    case "DownloadZIP":
      return (
        <Tooltip title={"ZIP"} {...tooltipPropsOverride}>
          <Button
            key="download-zip-button"
            disabled={disabled}
            onClick={onClick}
            className="action-btn-toolbar"
            shape={type == "inline" ? "circle" : undefined}
            icon={<FileZipOutlined />}
          >
            {buttonTextInline(extraInfo?.title ?? "Download ZIP")}
          </Button>
        </Tooltip>
      );
    case "Regenerate":
      return (
        <Tooltip title={"Regenerate"} {...tooltipPropsOverride}>
          <Button
            key="regenerate-button"
            disabled={disabled}
            onClick={onClick}
            className="action-btn-toolbar"
            shape={type == "inline" ? "circle" : undefined}
            icon={<ReloadOutlined />}
          >
            {buttonTextInline(extraInfo?.title ?? "Regenerate")}
          </Button>
        </Tooltip>
      );
    case "Publish":
      return (
        <Tooltip
          title={
            extraInfo?.status === TemplateStatus.PUBLISHED
              ? "Unpublish"
              : "Publish"
          }
        >
          <Button
            key="publish-toolbar-button"
            disabled={disabled}
            onClick={onClick}
            className="action-btn-toolbar"
            shape={undefined}
            icon={<PublishUnpublishIcon status={extraInfo?.status} />}
          >
            {buttonTextInline(
              extraInfo?.status === TemplateStatus.PUBLISHED
                ? "Unpublish"
                : "Publish",
            )}
          </Button>
        </Tooltip>
      );

    case "Create Marketing Material":
      return (
        <Tooltip title="Create Marketing Material">
          <Button
            key="create-marketing-material-toolbar-btn"
            disabled={disabled}
            onClick={onClick}
            className="action-btn-toolbar"
            shape={type == "inline" ? "circle" : undefined}
            icon={<NotificationOutlined />}
          >
            {buttonTextInline("Create Marketing Material")}
          </Button>
        </Tooltip>
      );

    case "Print":
      return (
        <Print
          extraInfo={extraInfo}
          disabled={disabled}
          tooltipProps={tooltipPropsOverride}
          onClick={onClick}
          shape={type == "inline" ? "circle" : undefined}
          buttonTextInline={buttonTextInline}
        />
      );

    default:
      const _exhaustiveCheck: never = buttonKey;
      throw Error(`Unknown button key: ${_exhaustiveCheck}`);
  }
};

type NewButtonProps = ButtonProps & {
  dropdownMenu?: React.ReactElement;
};

const NewButton = ({
  disabled,
  type,
  children,
  onClick,
  dropdownMenu,
  style,
}: PropsWithChildren<NewButtonProps>) => {
  const baseButtonProps = {
    ["data-cy"]: "new-toolbar-button",
    key: "new-toolbar-button",
    style,
    type,
    disabled,
    className: "action-btn-toolbar",
    icon: <PlusOutlined />,
  };

  if (dropdownMenu) {
    return (
      <Dropdown overlay={dropdownMenu} trigger={["click"]} arrow>
        <Button {...baseButtonProps}>{children}</Button>
      </Dropdown>
    );
  }

  return (
    <Button {...baseButtonProps} onClick={onClick}>
      {children}
    </Button>
  );
};

const renderSortBy = (sortingOrderBy: string[], defaultDirection?: string) => {
  const [sortBy, direction] = sortingOrderBy;
  const newDirection = direction || defaultDirection;
  const lowerCaseDirection = newDirection?.toLowerCase();
  return (
    <div className="sort-cascader">
      {lowerCaseDirection &&
        (lowerCaseDirection.startsWith("asc") ? (
          <ArrowUpOutlined />
        ) : (
          <ArrowDownOutlined />
        ))}
      {sortBy}
    </div>
  );
};

import { Radio } from "antd";
import { PrintMode, PrintModeOption } from "shared/types/printOptions";

export const PrintModeRadioGroup = ({
  value,
  onChange,
}: {
  value?: PrintMode[];
  onChange?: (value: PrintMode[]) => void;
}) => {
  const selectedValue = value?.join("_");
  return (
    <Radio.Group
      value={selectedValue}
      onChange={e => {
        if (e.target.value === PrintModeOption.SIMPLEX_DUPLEX) {
          onChange?.([PrintModeOption.SIMPLEX, PrintModeOption.DUPLEX]);
        } else {
          onChange?.([e.target.value]);
        }
      }}
    >
      <Radio value={PrintModeOption.SIMPLEX}>Simplex Only</Radio>
      <Radio value={PrintModeOption.DUPLEX}>Duplex Only</Radio>
      <Radio value={PrintModeOption.SIMPLEX_DUPLEX}>Simplex and Duplex</Radio>
    </Radio.Group>
  );
};

import styles from "./Portal.module.scss";
import { PortalConfigurationFields } from "./PortalConfigurationFields";
import { PortalConfigurationTable } from "./PortalConfigurationTable";
import { useIsScrolledToBottom } from "./useIsScrolledToBottom";

export const LeftPanel = () => {
  const { isScrolledToBottom, ref } = useIsScrolledToBottom();
  const leftPanelClassName = `${styles.leftPanelWrapper} ${
    !isScrolledToBottom ? styles.canScrollMore : ""
  }`;

  return (
    <div ref={ref} className={leftPanelClassName}>
      <PortalConfigurationFields />
      <PortalConfigurationTable />
    </div>
  );
};

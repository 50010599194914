import { Invoice } from "shared/types/salesEnablement";

import { ITableColumnURL } from "shared/components/dataListURL/DataListURLTable";
import { fields } from "../fields";
import { Link } from "react-router-dom";

export const generateInvoiceColumns = (): ITableColumnURL<
  keyof Invoice,
  Invoice
>[] => {
  return [
    {
      title: "Invoice No.",
      dataIndex: "id",
      key: "id",
      width: 180,
      sorter: fields.id?.sorterFn,
      render: (id: string) => (
        <Link to={`/sales-enablement/invoices/${id}`}>{id}</Link>
      ),
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      width: 100,
      sorter: fields.amount?.sorterFn,
      render: (amount: number) => <span>${amount.toFixed(2)}</span>,
    },
    {
      title: "Post Date",
      dataIndex: "postDate",
      key: "postDate",
      width: 150,
      sorter: fields.postDate?.sorterFn,
      render: (date: string) => (
        <span>{new Date(date).toLocaleDateString()}</span>
      ),
    },
  ];
};

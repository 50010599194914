/* eslint-disable react/display-name */
import { CalendarOutlined, SearchOutlined } from "@ant-design/icons";
import moment from "moment";
import { Dispatch, ReactText, SetStateAction } from "react";
import { IAdValidations } from "screens/adLibrary/adImport/adValidationUtils";
import Tags from "screens/adLibrary/adLoad/adLoadDrawer/shared/components/Tags";
import { AdType, CallToAction } from "screens/adLibrary/facebookUtils/types";
import NameCardCell from "screens/adLibrary/shared/components/NameCardCell";
import {
  getColorFromQcStatus,
  getCtaText,
  qcStatusOptions,
} from "screens/adLibrary/utils";
import AutoEllipsisText from "shared/components/AutoEllipsisText";
import { CopyUrlToClipboard } from "shared/components/dataList/CopyUrlToClipboard";
import { DateFilterDropdown } from "shared/components/DateFilterDropdown";
import { SearchFilterDropdown } from "shared/components/SearchFilterDropdown";
import { TimeAgo } from "shared/components/TimeAgo";
import { dateFormat } from "shared/constants/dataManagement";
import { dateFormat as adLibraryDateFormat } from "shared/types/adLibrary";
import { AdLibraryTableColumn, IAd, QcStatus } from "shared/types/adLibrary";
import { months } from "utils/helpers.date";
import { terms } from "utils/terms";
import StatusSelector from "../adTableListContainer/StatusSelector";
import { Tag } from "antd";
import { getValidationMessage } from "screens/adLibrary/shared/validationUtils";
import { isFeatureEnabled } from "utils/helpers";
import RunDate from "./RunDate";
import { parse } from "date-fns";

type Model = {
  year: string;
  name: string;
};

type Params<T extends IAd = IAd> = {
  selectedIds: string[];
  setPreviewAd: Dispatch<SetStateAction<T | null>>;
  onEditClick?: (ad: T) => void;
  getCellProps: (
    adId: string,
    field: keyof IAdValidations,
  ) => {
    className: string;
  };
  onStatusChange: (status: QcStatus, ad: T, selectedIds: ReactText[]) => void;
  onRunDateChange: (args: {
    range: [Date | null, Date | null];
    toAll: boolean;
    ad: T;
    isPlannerInstance: boolean;
  }) => void;
  extraColumns?: AdLibraryTableColumn<T>[];
};

export const getColumns = <T extends IAd = IAd>({
  selectedIds,
  setPreviewAd,
  onEditClick,
  getCellProps,
  onStatusChange,
  onRunDateChange,
  extraColumns = [],
}: Params<T>): AdLibraryTableColumn<T>[] => {
  const columns: AdLibraryTableColumn<T>[] = [
    {
      key: "name",
      title: "Name",
      dataIndex: ["inputParameters", "name"],
      render: (name: string, ad) => (
        <NameCardCell
          ad={ad}
          name={name}
          onImageClick={() => {
            setPreviewAd(ad);
          }}
          onCellClick={() => onEditClick && onEditClick(ad)}
        />
      ),
      fixed: "left",
      filterDropdown: SearchFilterDropdown,
      filterIcon: (filtered: boolean) => (
        <SearchOutlined color={filtered ? "#1890ff" : undefined} />
      ),
      onCell: record => getCellProps(record.id, "name"),
      width: 250,
    },
    ...extraColumns,
    {
      key: "type",
      title: "Ad Format",
      dataIndex: ["type"],
      render: (adFormat: string) =>
        adFormat === AdType.InstantExperience ? "Instant Experience" : adFormat,
      filters: Object.values(AdType).map(adType => {
        return {
          text: adType === "InstantExperience" ? "Instant Experience" : adType,
          value: adType,
        };
      }),
      onCell: record => getCellProps(record.id, "adFormat"),
      width: 150,
      exportFn: (record: IAd) =>
        record.type === AdType.InstantExperience
          ? "Instant Experience"
          : record.type,
    },
    {
      key: "runDate",
      title: "Run Date",
      dataIndex: ["runDatesStr"],
      render: (runDates: string | undefined, record) => {
        const runDateStr = runDates ?? record.runDatesStr;
        const [startDateStr, endDateStr] = runDateStr?.split(",") ?? [];
        const startDate = startDateStr
          ? parse(startDateStr, adLibraryDateFormat, new Date())
          : undefined;
        const endDate = endDateStr
          ? parse(endDateStr, adLibraryDateFormat, new Date())
          : undefined;
        return (
          <RunDate<T>
            ad={record}
            value={{ start: startDate, end: endDate }}
            onComplete={({
              range,
              toAll,
              ad,
              isPlannerInstance,
            }: {
              range: [start: Date | null, end: Date | null];
              toAll: boolean;
              ad: T;
              isPlannerInstance: boolean;
            }) => onRunDateChange({ range, toAll, ad, isPlannerInstance })}
          />
        );
      },
      width: 200,
    },
    {
      key: "qcStatus",
      title: "Status",
      dataIndex: ["qcStatus"],
      render: (status: QcStatus, record) => (
        <StatusSelector<QcStatus>
          status={record.validation?.isValid ? status : QcStatus.ERROR}
          tooltip={getValidationMessage(record.validation?.issues)}
          onStatusChange={status => onStatusChange(status, record, selectedIds)}
          getStatusColor={getColorFromQcStatus}
          statusOptions={qcStatusOptions()}
        />
      ),
      filters: qcStatusOptions(),
      width: 300,
    },
    {
      key: "planners",
      title: "Campaign Planners",
      dataIndex: ["planners"],
      render: (_, record) => (
        <div>
          {record.planners?.map(planner => (
            <Tag key={planner.id}>{planner.name}</Tag>
          ))}
          {record.instances?.map(instance => (
            <Tag key={instance.id} color="green">
              {instance.name}
            </Tag>
          ))}
        </div>
      ),
      filterDropdown: SearchFilterDropdown,
      filterIcon: (filtered: boolean) => (
        <SearchOutlined color={filtered ? "#1890ff" : undefined} />
      ),
      width: 200,
    },
    {
      key: "createdAt",
      title: "Created",
      dataIndex: ["createdAt"],
      render: (date: Date) => (
        <TimeAgo date={date} dataCy="created-at-time-ago" />
      ),
      filterDropdown: DateFilterDropdown,
      filterIcon: (filtered: boolean) => (
        <CalendarOutlined color={filtered ? "#1890ff" : undefined} />
      ),
      width: 155,
      exportFn: (record: IAd) => {
        const date = record.createdAt;
        return date ? moment(date).format(dateFormat) : "";
      },
    },
    {
      key: "updatedAt",
      title: "Last Edit",
      dataIndex: ["updatedAt"],
      render: (date: Date) => <TimeAgo date={date} />,
      filterDropdown: DateFilterDropdown,
      filterIcon: (filtered: boolean) => (
        <CalendarOutlined color={filtered ? "#1890ff" : undefined} />
      ),
      width: 155,
      exportFn: (record: IAd) => {
        const date = record.updatedAt;
        return date ? moment(date).format(dateFormat) : "";
      },
    },
    {
      key: "updatedBy",
      title: "Last Edit By",
      dataIndex: ["updatedBy"],
      render: (text: string) => <AutoEllipsisText>{text}</AutoEllipsisText>,
      filterDropdown: SearchFilterDropdown,
      filterIcon: (filtered: boolean) => (
        <SearchOutlined color={filtered ? "#1890ff" : undefined} />
      ),
      width: 280,
    },
    {
      key: "tags",
      title: "Tags",
      dataIndex: ["inputParameters", "tags"],
      render: tags => <Tags tags={tags} />,
      filterDropdown: SearchFilterDropdown,
      filterIcon: (filtered: boolean) => (
        <SearchOutlined color={filtered ? "#1890ff" : undefined} />
      ),
      width: 190,
    },
    {
      key: "adUrl",
      title: "Ad URL",
      dataIndex: ["adUrl"],
      render: (text: string) => <CopyUrlToClipboard url={text} />,
      filterDropdown: SearchFilterDropdown,
      filterIcon: (filtered: boolean) => (
        <SearchOutlined color={filtered ? "#1890ff" : undefined} />
      ),
      width: 380,
    },
    {
      key: "destinationUrl",
      title: "Destination URL",
      dataIndex: ["inputParameters", "destinationUrl"],
      render: (text: string) => <CopyUrlToClipboard url={text} />,
      filterDropdown: SearchFilterDropdown,
      filterIcon: (filtered: boolean) => (
        <SearchOutlined color={filtered ? "#1890ff" : undefined} />
      ),
      width: 380,
    },
    {
      key: "postCopy",
      title: "Primary Text",
      render: (_, ad) => (
        <AutoEllipsisText>
          {ad.visuals.postCopy ?? ad.visuals.primaryText}
        </AutoEllipsisText>
      ),
      filterDropdown: SearchFilterDropdown,
      filterIcon: (filtered: boolean) => (
        <SearchOutlined color={filtered ? "#1890ff" : undefined} />
      ),
      onCell: record => getCellProps(record.id, "postCopy"),
      width: 360,
    },
    {
      key: "headline",
      title: "Headline",
      dataIndex: ["visuals", "headline"],
      render: (text: string) => <AutoEllipsisText>{text}</AutoEllipsisText>,
      filterDropdown: SearchFilterDropdown,
      filterIcon: (filtered: boolean) => (
        <SearchOutlined color={filtered ? "#1890ff" : undefined} />
      ),
      onCell: record => getCellProps(record.id, "headline"),
      width: 370,
    },
    {
      key: "displayUrl",
      title: "Display URL",
      dataIndex: ["visuals", "displayUrl"],
      render: (text: string) => <AutoEllipsisText>{text}</AutoEllipsisText>,
      filterDropdown: SearchFilterDropdown,
      filterIcon: (filtered: boolean) => (
        <SearchOutlined color={filtered ? "#1890ff" : undefined} />
      ),
      width: 370,
    },
    {
      key: "headlineDescription",
      title: "Description",
      dataIndex: ["visuals", "headlineDescription"],
      render: (text: string) => <AutoEllipsisText>{text}</AutoEllipsisText>,
      filterDropdown: SearchFilterDropdown,
      filterIcon: (filtered: boolean) => (
        <SearchOutlined color={filtered ? "#1890ff" : undefined} />
      ),
      onCell: record => getCellProps(record.id, "headlineDescription"),
      width: 370,
    },
    {
      key: "utm",
      title: "UTM",
      dataIndex: ["inputParameters", "utm"],
      render: (text: string) => <AutoEllipsisText>{text}</AutoEllipsisText>,
      filterDropdown: SearchFilterDropdown,
      filterIcon: (filtered: boolean) => (
        <SearchOutlined color={filtered ? "#1890ff" : undefined} />
      ),
      onCell: record => getCellProps(record.id, "utm"),
      width: 380,
    },
    {
      key: "campaignStartDate",
      title: "Start Date",
      dataIndex: ["inputParameters", "campaignStartDate"],
      render: date => (date ? moment(parseInt(date)).format(dateFormat) : ""),
      filterDropdown: DateFilterDropdown,
      filterIcon: (filtered: boolean) => (
        <CalendarOutlined color={filtered ? "#1890ff" : undefined} />
      ),
      width: 120,
      exportFn: (record: IAd) => {
        const date = record.inputParameters.campaignStartDate;
        return date ? moment(parseInt(date)).format(dateFormat) : "";
      },
    },
    {
      key: "campaignEndDate",
      title: "End Date",
      dataIndex: ["inputParameters", "campaignEndDate"],
      render: (date: string) =>
        date ? moment(parseInt(date)).format(dateFormat) : "",
      filterDropdown: DateFilterDropdown,
      filterIcon: (filtered: boolean) => (
        <CalendarOutlined color={filtered ? "#1890ff" : undefined} />
      ),
      width: 120,
      exportFn: (record: IAd) => {
        const date = record.inputParameters.campaignEndDate;
        return date ? moment(parseInt(date)).format(dateFormat) : "";
      },
    },
    {
      key: "audiences",
      title: "Audience",
      dataIndex: ["inputParameters", "audiences"],
      render: audiences => <Tags tags={audiences} />,
      filterDropdown: SearchFilterDropdown,
      filterIcon: (filtered: boolean) => (
        <SearchOutlined color={filtered ? "#1890ff" : undefined} />
      ),
      width: 250,
    },
    {
      key: "ctaButtonText",
      title: "CTA Button",
      dataIndex: ["visuals", "ctaButtonText"],
      render: cta => getCtaText(cta),
      filters: Object.values(CallToAction)
        .filter(Boolean)
        .map(status => {
          return { text: status, value: status };
        }),
      onCell: record => getCellProps(record.id, "ctaButtonText"),
      width: 130,
    },
    {
      key: "store",
      title: terms.accountName,
      dataIndex: ["inputParameters", "client"],
      render: (text: string) => (
        <AutoEllipsisText>{text?.split(",").join(", ") || ""}</AutoEllipsisText>
      ),
      width: 200,
    },
    {
      key: "package",
      title: "Package",
      dataIndex: ["inputParameters", "package"],
      render: (text: string) => <AutoEllipsisText>{text}</AutoEllipsisText>,
      filters: ["A", "B", "C", "D"].map(packageCategory => {
        return { text: packageCategory, value: packageCategory };
      }),
      width: 100,
    },
    {
      key: "brand",
      title: "Brand",
      dataIndex: ["inputParameters", "oems"],
      render: (oemsToRender: string[]) => (
        <AutoEllipsisText>{oemsToRender?.join(", ") || ""}</AutoEllipsisText>
      ),
      filterDropdown: SearchFilterDropdown,
      filterIcon: (filtered: boolean) => (
        <SearchOutlined color={filtered ? "#1890ff" : undefined} />
      ),
      width: 210,
    },
    {
      key: "modelYear",
      title: "Model Year",
      dataIndex: ["inputParameters", "models"],
      render: (models: Model[]) =>
        models?.map((model: Model) => model.year).join(", "),
      filterDropdown: SearchFilterDropdown,
      filterIcon: (filtered: boolean) => (
        <SearchOutlined color={filtered ? "#1890ff" : undefined} />
      ),
      width: 110,
      exportFn: (record: IAd) =>
        record.inputParameters?.models?.map(model => model.year).join(",") ??
        "",
    },
    {
      key: "modelName",
      title: "Model Name",
      dataIndex: ["inputParameters", "models"],
      render: (models: Model[]) =>
        models?.map((model: Model) => model.name).join(", "),
      filterDropdown: SearchFilterDropdown,
      filterIcon: (filtered: boolean) => (
        <SearchOutlined color={filtered ? "#1890ff" : undefined} />
      ),
      width: 170,
      exportFn: (record: IAd) =>
        record.inputParameters?.models?.map(model => model.name).join(",") ??
        "",
    },
    {
      key: "year",
      title: "Year",
      dataIndex: ["inputParameters", "year"],
      render: (text: string) => <AutoEllipsisText>{text}</AutoEllipsisText>,
      width: 130,
    },
    {
      key: "month",
      title: "Month",
      dataIndex: ["inputParameters", "month"],
      render: monthsToRender => <Tags tags={monthsToRender} />,
      filters: months.map(month => {
        return { text: month, value: month };
      }),
      width: 200,
    },
    {
      key: "version",
      title: "Version",
      dataIndex: ["inputParameters", "version"],
      render: (text: string) => <AutoEllipsisText>{text}</AutoEllipsisText>,
      width: 130,
    },
    {
      key: "strategy",
      title: "Strategy",
      dataIndex: ["inputParameters", "strategy"],
      render: (text: string) => <AutoEllipsisText>{text}</AutoEllipsisText>,
      filters: ["Prospecting", "Retargeting"].map(strategy => {
        return { text: strategy, value: strategy };
      }),
      width: 130,
    },
    {
      key: "storyPlacement",
      title: "Story Placement",
      dataIndex: ["inputParameters", "storyPlacement"],
      render: (text: string) => <AutoEllipsisText>{text}</AutoEllipsisText>,
      filters: ["Yes", "No"].map(placement => {
        return { text: placement, value: placement };
      }),
      width: 130,
    },
  ];

  if (!isFeatureEnabled("ENABLE_CAMPAIGN_PLANNER")) {
    return columns.filter(column => column.key !== "planners");
  }
  return columns;
};

import { states } from "shared/constants/states";
import { startCase } from "lodash";
import { TemplateStatus } from "shared/types/salesEnablement";

const languageOptions = [
  { key: "en", value: "en", label: "English" },
  { key: "es", value: "es", label: "Spanish" },
];

const generateOptions = (options: string[]) => {
  return options.map(option => {
    return { label: startCase(option), key: option, value: option };
  });
};

const salesCycleOptions = [
  {
    key: "prospecting",
    value: "prospecting",
    label: "Prospecting / DM Conversations",
  },
  ...generateOptions([
    "employeeEnrollment",
    "productSpecific",
    "servicing",
    "brokerFacing",
    "recruiting",
  ]),
];

const materialTypeOptions = generateOptions([
  "ad",
  "article",
  "brochure",
  "buckSlip",
  "contactCard",
  "coverSheet",
  "email",
  "flyer",
  "infographic",
  "interestForm",
  "letter",
  "postcard",
  "poster",
  "survey",
]);

export const audienceOptions = [
  ...generateOptions([
    "broker",
    "employee",
    "producer",
    "publicSector",
    "recruit",
  ]),
  {
    key: "employer",
    value: "employer",
    label: "Employer/DM",
  },
  {
    key: "policyCertificateHolder",
    value: "policyCertificateHolder",
    label: "Policyholder/Certificate Holder",
  },
];

const statusOptions: {
  key: TemplateStatus;
  value: TemplateStatus;
  label: Capitalize<Lowercase<TemplateStatus>>;
  color: "blue" | "green";
}[] = [
  {
    key: TemplateStatus.PUBLISHED,
    value: TemplateStatus.PUBLISHED,
    label: "Published",
    color: "green",
  },
  {
    key: TemplateStatus.UNPUBLISHED,
    value: TemplateStatus.UNPUBLISHED,
    label: "Unpublished",
    color: "blue",
  },
];

export const customizableMap = {
  customizable: "Customizable",
  nonCustomizable: "Non-customizable",
};

export const customizableOptions = Object.keys(customizableMap).map(key => {
  return {
    key,
    value: key,
    label: customizableMap[key as keyof typeof customizableMap],
  };
});

export const fields = {
  name: {
    label: "Template Name",
    key: "name",
    required: true,
    options: undefined,
    tab: "settings",
    placeholder: "Enter Name",
  },
  description: {
    label: "Template Description",
    key: "description",
    required: false,
    options: undefined,
    tab: "settings",
    placeholder: "Enter Description",
  },
  locations: {
    label: "State(s) to be Distributed In (Ind) / Account Situs State (Grp)",
    key: "locations",
    required: true,
    options: Object.values(states).map(state => {
      return {
        label: `${state.id} - ${state.name}`,
        value: state.id,
        key: state.id,
      };
    }),
    tab: "settings",
  },
  salesCycle: {
    label: "Sales Cycle",
    key: "salesCycle",
    required: true,
    options: salesCycleOptions,
    tab: "settings",
    placeholder: "Select Sales Cycle",
  },
  audience: {
    label: "Audience",
    key: "audience",
    required: true,
    options: audienceOptions,
    tab: "settings",
    placeholder: "Select Audience",
  },
  materialType: {
    label: "Material Type",
    key: "materialType",
    required: true,
    options: materialTypeOptions,
    tab: "settings",
    placeholder: "Select Material Type",
  },
  customizable: {
    label: "Customizable",
    key: "customizable",
    required: false,
    options: customizableOptions,
  },
  expirationDate: {
    label: "Expiration Date",
    key: "expirationDate",
    required: true,
    tab: "components",
  },
  deliveryMethods: {
    label: "Delivery Methods",
    key: "deliveryMethods",
    required: true,
    tab: "components",
  },
  englishFormNumber: {
    label: "English Form Number",
    key: "englishFormNumber",
    required: true,
    tab: "components",
  },
  spanishFormNumber: {
    label: "Spanish Form Number",
    key: "spanishFormNumber",
    required: true,
    tab: "components",
  },
  printOptionId: {
    label: "Print Option",
    key: "printOptionId",
    required: false,
    tab: "components",
  },
  englishDefaultEmailSubject: {
    label: "Default Email Subject",
    key: "englishDefaultEmailSubject",
    required: true,
    tab: "components",
  },
  spanishDefaultEmailSubject: {
    label: "Default Email Subject",
    key: "spanishDefaultEmailSubject",
    required: true,
    tab: "components",
  },
  language: {
    label: "Language",
    key: "language",
    required: true,
    options: languageOptions,
  },
  tags: {
    label: "Tags",
    key: "tags",
    required: false,
    options: undefined,
  },
  status: {
    label: "Status",
    key: "status",
    required: false,
    options: statusOptions,
  },
} as const;

export type Tab = "settings" | "components";
export type Fields = typeof fields;

export type TemplateFormKeys<
  T extends Tab | undefined = undefined,
  K = keyof Fields,
> = K extends keyof Fields
  ? "tab" extends keyof Fields[K]
    ? T extends undefined
      ? K
      : [(typeof fields)[K]["tab"]] extends [T]
      ? K
      : never
    : never
  : never;

export type TemplateForm = Record<TemplateFormKeys, any>;

export const tabFields = <T extends Tab>(tab: T) => {
  return Object.keys(fields).filter(key => {
    const field = fields[key as keyof Fields];
    return "tab" in field && field.tab === tab;
  }) as Partial<TemplateFormKeys<Tab>>[];
};

export const fieldIsInvalid = (v: Partial<TemplateForm>) =>
  Array.isArray(v) ? !v.length : !v;

import {
  useContext,
  createContext,
  ReactNode,
  useMemo,
  useState,
  useCallback,
  useEffect,
} from "react";
import { Layer, TemplateFile } from "shared/types/salesEnablement";
import { useIdmlData } from "./useIDMLData";
import { RenderableHtmlVariables, useHTMLData } from "./useHTMLData";
import { MarketingMaterial } from "shared/types/marketingMaterials";
import { useRenderIDML } from "./useRenderIDML";
import { IdmlPreview } from "./useFileData";

export type RenderingPreviewStatus = "loading" | "done" | "error" | "idle";

type ContextType = {
  htmlIframeRef: HTMLIFrameElement | null;
  setHtmlIframeRef: (ref: HTMLIFrameElement | null) => void;
  file?: TemplateFile;
  onLayerHover: (layer?: Layer) => void;
  onLayerEdit: (data: { data: RenderableHtmlVariables }) => void;
  idmlPreview: IdmlPreview;
  renderingPreviewStatus: RenderingPreviewStatus;
  isIdmlLoading: boolean;
  isIdmlFetching: boolean;
};

const Context = createContext<ContextType | null>(null);

export const useTemplateRenderContext = () => {
  const ctx = useContext(Context);

  if (!ctx) {
    throw new Error(
      "useTemplateRenderContext must be used within TemplateRenderProvider.",
    );
  }
  return ctx;
};

type Props = {
  children: ReactNode;
  file?: TemplateFile;
  iframeRef?: HTMLIFrameElement;
  material: Partial<MarketingMaterial> | undefined;
  forceUrl?: string;
};
const Provider = ({ children, file, material, forceUrl }: Props) => {
  const [htmlIframeRef, setHtmlIframeRef] = useState<HTMLIFrameElement | null>(
    null,
  );

  const onLayerHover = useCallback(
    (layer?: Layer) => {
      htmlIframeRef?.contentWindow?.postMessage({
        type: "hover",
        data: layer?.id,
      });
    },
    [htmlIframeRef],
  );

  const onLayerEdit = useCallback(
    ({ data: layerData }: { data: RenderableHtmlVariables }) => {
      htmlIframeRef?.contentWindow?.postMessage({
        type: "edit",
        value: JSON.stringify(layerData),
      });
    },
    [htmlIframeRef],
  );

  const sp = useMemo(() => file?.spMetadata, [file]);

  const renderingPreviewStatus = useMemo<RenderingPreviewStatus>(() => {
    if (!file) return "idle";

    if (file.status === "uploading") return "loading";
    else if (file.status === "done") return "done";

    return "idle";
  }, [file]);

  const { data, variables } = useIdmlData({ file, material });
  const htmlData = useHTMLData({ file, material });

  const {
    data: src,
    isLoading: isIdmlLoading,
    isFetching: isIdmlFetching,
    isError,
  } = useRenderIDML({ sp, data, variables }, !forceUrl);

  const idmlPreview = useMemo(() => {
    if (forceUrl) {
      return {
        src: forceUrl,
        isError: false,
        isLoading: false,
      };
    }
    return file?.type === "pdf"
      ? {
          src: file?.url,
          isError: false,
          isLoading: false,
        }
      : {
          src,
          isError,
          isLoading: isIdmlLoading,
        };
  }, [src, isError, isIdmlLoading, file, forceUrl]);

  useEffect(() => {
    if (!htmlIframeRef) return;
    onLayerEdit(htmlData);
  }, [htmlData, onLayerEdit, htmlIframeRef]);

  const memoizedContextValue: ContextType = useMemo(
    () => ({
      htmlIframeRef,
      setHtmlIframeRef,
      file,
      onLayerHover,
      onLayerEdit,
      renderingPreviewStatus,
      idmlPreview: {
        ...idmlPreview,
        src: idmlPreview.src,
      },
      isIdmlLoading,
      isIdmlFetching,
    }),
    [
      htmlIframeRef,
      setHtmlIframeRef,
      file,
      onLayerHover,
      onLayerEdit,
      idmlPreview,
      renderingPreviewStatus,
      isIdmlLoading,
      isIdmlFetching,
    ],
  );
  return (
    <Context.Provider value={memoizedContextValue}>{children}</Context.Provider>
  );
};

export default Provider;

import { IDataListURLFields } from "shared/components/dataListURL/types";
import { StringParam } from "use-query-params";
import { compareStringBy } from "utils/helpers.table";

export const fields: IDataListURLFields<string, any> = {
  id: {
    filterFn: (value, record) => record.id.includes(value),
    sorterFn: compareStringBy("id"),
    queryParamConfigFilter: StringParam,
  },
  amount: {
    filterFn: (value, record) => record.amount >= value,
    sorterFn: (a, b) => a.amount - b.amount,
    queryParamConfigFilter: StringParam,
  },
  docDate: {
    filterFn: (value, record) => record.docDate.includes(value),
    sorterFn: compareStringBy("docDate"),
    queryParamConfigFilter: StringParam,
  },
};

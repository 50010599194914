import DataListURLTable from "shared/components/dataListURL/DataListURLTable";
import { useWindowSize } from "shared/hooks/useWindowSize";
import { RightOutlined } from "@ant-design/icons";
import {
  PrintOptionsTableColumns,
  PrintOptionTableItem,
} from "shared/types/printOptions";
import { getColumns } from "./printOptionsTable/columns";
import { printOptionsFieldsKeys } from "./printOptionsTable/fields";
import { NestedCopiesTable } from "./printOptionsTable/NestedCopiesTable";
import { useIsSalesEnablementAdmin } from "shared/hooks/useIsAdmin";

type Props = {
  data: PrintOptionTableItem[];
};

export const PrintOptionsTable = ({ data }: Props) => {
  const { windowInnerHeight, windowInnerWidth } = useWindowSize();
  const isAdmin = useIsSalesEnablementAdmin();
  const columns = getColumns<PrintOptionTableItem>(isAdmin);
  return (
    <DataListURLTable<PrintOptionsTableColumns, PrintOptionTableItem>
      loading={false}
      dataSource={data}
      columns={columns}
      pagination={false}
      size="small"
      scroll={{ y: windowInnerHeight - 265, x: windowInnerWidth - 20 }}
      type="hoverActionsVirtualTable"
      fieldKeys={printOptionsFieldsKeys}
      expandable={{
        expandedRowRender: record => {
          return <NestedCopiesTable costPerCopy={record.costPerCopy} />;
        },
        rowExpandable: () => true,
        expandIcon: ({ expanded, onExpand, record }) => (
          <RightOutlined
            style={{
              transition: "all 0.1s ease-in-out",
              transform: `${
                expanded ? "rotate(90deg)" : "rotate(0deg)"
              } scale(0.8)`,
            }}
            onClick={e => onExpand(record, e)}
          />
        ),
      }}
    />
  );
};

import { Template } from "shared/types/salesEnablement";
import { useFetchPrintOptions } from "./useFetchPrintOptions";
import { useSalesEnablementContext } from "screens/designStudio/hooks/SalesEnablementDataProvider";

export const usePrintOption = (id: string) => {
  const { data: printOptions, isLoading: isPrintOptionsLoading } =
    useFetchPrintOptions();
  const { templates, isTemplateLoading } = useSalesEnablementContext();
  const printOption = printOptions?.find(option => option.id === id);
  const associatedTemplates = templates
    .filter(template => template.printOptionId === id)
    .map(template => template.id);
  return {
    printOption: printOption
      ? {
          ...printOption,
          associatedTemplates,
        }
      : undefined,
    isLoading: isPrintOptionsLoading || isTemplateLoading,
  };
};

export const usePrintOptionByTemplate = (template: Template) => {
  const { data: printOptions, isLoading: isPrintOptionsLoading } =
    useFetchPrintOptions();
  return {
    printOption: printOptions?.find(
      option => option.id === template.printOptionId,
    ),
    isLoading: isPrintOptionsLoading,
  };
};

import { Drawer } from "antd";
import { useParams } from "react-router-dom";
import { usePrintOption } from "shared/hooks/salesEnablement/usePrintOption";
import { PrintOptionsForm } from "./printOptionsDrawer/PrintOptionsForm";
import { DrawerFooter } from "./printOptionsDrawer/printOptionsForm/DrawerFooter";

const formId = "print-option-form";
export const PrintOptionsDrawer = ({
  isVisible,
  onClose,
}: {
  isVisible: boolean;
  onClose: () => void;
}) => {
  const { printOptionId } = useParams();
  const { printOption } = usePrintOption(printOptionId ?? "");

  return (
    <Drawer
      title={`${printOptionId ? "Edit" : "New"} Print Option`}
      width={928}
      visible={isVisible}
      onClose={onClose}
      closable={false}
      destroyOnClose
      footer={<DrawerFooter formId={formId} onCancel={onClose} />}
    >
      {isVisible && (
        <PrintOptionsForm
          formId={formId}
          printOption={printOption}
          onClose={onClose}
        />
      )}
    </Drawer>
  );
};

import { useStartExportAssetBatches } from "./useExportAssetBatches";
import { useExportExecutionArns } from "./useExportExecutionArns";
import { useAdEngineActions } from "redux/assetExporter/assetExporter.slice";
import { StringParam, useQueryParams } from "use-query-params";
import { useFeedContext } from "screens/assetExporter/feedConfigurationV2/shared/contexts/FeedContext";
import { useAppSelector } from "../useAppSelector";
import { AppState } from "redux/store";
import {
  FeedTblRow,
  MappingDataForExportPortal,
  PortalData,
} from "shared/types/assetExporter";
import { getExportAssetBatchesBody } from "screens/assetExporter/feedConfigurationV2/exportDrawer/shared/utils/getExportAssetBatchesBody";
import {
  AdInfoUpdates,
  AdType,
  ExportDestination,
  RecommendedColumnMatches,
} from "screens/assetExporter/feedConfigurationV2/exportDrawer/shared/types";
import { IFilter } from "./useFeedConfigFetch";
import { useDataList } from "shared/components/dataList/useDataList";

interface UseAssetBatchExportGeneration {
  feedId: string;
  selectedRows: string[];
  selectedAssetBatches: string[];
  exportDestination: ExportDestination;
  columnMatches?: RecommendedColumnMatches;
  adInfoUpdates?: AdInfoUpdates;
  adType?: AdType | null;
  portalOptionMatches?: MappingDataForExportPortal;
  portalData?: PortalData;
  clientSlug?: string;
  filterArgs?: IFilter[];
  searchValue?: string;
}

export const useAssetBatchExportGeneration = ({
  feedId,
  selectedRows,
  selectedAssetBatches,
  exportDestination,
  columnMatches,
  adInfoUpdates,
  adType,
  portalOptionMatches,
  portalData,
  clientSlug,
  filterArgs,
  searchValue,
}: UseAssetBatchExportGeneration) => {
  const [queryParams] = useQueryParams({ feedName: StringParam });
  const { mutate: exportAssetBatches, isLoading: isStartingExport } =
    useStartExportAssetBatches();
  const { assetBatchesForSelectedRows } = useFeedContext();
  const user = useAppSelector((state: AppState) => state.auth.user);
  const { exportExecutionArns, handleSetExportExecutionArns } =
    useExportExecutionArns();
  const {
    setExportFeedName,
    setExportAssetBatchNames,
    setExportRowCount,
    setExportClientSlug,
  } = useAdEngineActions();
  const [dataList] = useDataList<FeedTblRow>();

  const exportAssetBatchNames = assetBatchesForSelectedRows
    .filter(assetBatch =>
      selectedAssetBatches.includes(assetBatch.assetBatchId ?? ""),
    )
    .map(assetBatch => assetBatch.name);

  const handleStartExportAssetBatches = ({
    onSettled,
  }: {
    onSettled?: () => void;
  }) => {
    if (!exportDestination) return;
    const body = getExportAssetBatchesBody({
      feedId,
      user,
      selectedRows,
      selectedAssetBatches,
      exportDestination,
      columnMatches,
      adInfoUpdates,
      adType,
      portalOptionMatches,
      portalData,
      filterArgs,
      searchValue,
      selectAll: dataList.selectAll,
    });

    exportAssetBatches(body, {
      onSuccess: data => {
        if (!data) return;
        handleSetExportExecutionArns(
          exportExecutionArns.concat(data.executionArn),
        );
        if (exportDestination === ExportDestination.AdLibrary) {
          setExportFeedName({ exportFeedName: queryParams.feedName });
          setExportAssetBatchNames({ exportAssetBatchNames });
          setExportRowCount({ exportRowCount: selectedRows.length });
        }
        if (exportDestination === ExportDestination.Portal) {
          setExportClientSlug({ exportClientSlug: clientSlug });
        }
      },
      onSettled: () => onSettled?.(),
    });
  };

  return {
    isStartingExport,
    handleStartExportAssetBatches,
  };
};

import { StringParam } from "serialize-query-params";
import { Field } from "shared/components/dataListURL/types";
import { compareStringBy, onFilterBy } from "utils/helpers.table";
import {
  PrintOptionsTableColumns,
  PrintOptionTableItem,
} from "shared/types/printOptions";

export type PrintOptionsField = Field<PrintOptionTableItem>;

export type PrintOptionsKey = PrintOptionsTableColumns;
type Fields = Partial<Record<PrintOptionsKey, PrintOptionsField>>;

export type PrintOptionsFields = keyof Fields;

export const printOptionsFields: Fields = {
  name: {
    filterFn: onFilterBy("name", {
      matchMode: "includes",
      caseInsensitive: true,
    }),
    sorterFn: compareStringBy("name"),
    queryParamConfigFilter: StringParam,
  },
  printMode: {
    filterFn: onFilterBy(({ printMode }) => printMode.join(","), {
      matchMode: "includes",
    }),
    queryParamConfigFilter: StringParam,
  },
  weight: {
    filterFn: onFilterBy("weight", {
      matchMode: "equals",
      caseInsensitive: true,
    }),
    sorterFn: compareStringBy("weight"),
    queryParamConfigFilter: StringParam,
  },
  referenceNumber: {
    filterFn: onFilterBy("referenceNumber", {
      matchMode: "includes",
      caseInsensitive: true,
    }),
    sorterFn: compareStringBy("referenceNumber"),
    queryParamConfigFilter: StringParam,
  },
};

export const printOptionsFieldsKeys = Object.keys(printOptionsFields) as Array<
  keyof typeof printOptionsFields
>;

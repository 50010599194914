import { Checkbox, Form, FormInstance, Select, Typography } from "antd";
import {
  MarketingMaterialPrintDeliveryForm,
  PrintDeliveryMaterialItem,
} from "shared/types/marketingMaterials";
import styles from "./PrintForm.module.scss";
import { Rule } from "antd/lib/form";
import { PrintOptionRateTable } from "shared/components/salesEnablement/printOption/PrintOptionRateTable";
import CopiesField from "./printForm/CopiesField";
import { capitalize } from "lodash";
import {
  PrintMode,
  PrintOptionCopyType,
  PrintOptionItem,
} from "shared/types/printOptions";
import { PrintOptionProductionTimeCard } from "./printForm/PrintOptionProductionTimeCard";

export const PrintForm = ({
  form,
  materialId,
  printOption,
  value: material,
  onChange,
}: {
  form: FormInstance<MarketingMaterialPrintDeliveryForm>;
  materialId: string;
  printOption: PrintOptionItem;
  value?: PrintDeliveryMaterialItem;
  onChange?: (material: Partial<PrintDeliveryMaterialItem>) => void;
}) => {
  if (!material) return null;

  const getPriceByQuantity = (quantity: number): Record<PrintMode, number> => {
    if (printOption?.copySelection === PrintOptionCopyType.RANGE) {
      const entry = printOption.costPerCopy.find(item => {
        const min = item.quantity?.min || 0;
        const max = item.quantity?.max;
        return quantity >= min && (max ? quantity <= max : true);
      });
      if (entry)
        return {
          SIMPLEX: Number(entry.cost.SIMPLEX),
          DUPLEX: Number(entry.cost.DUPLEX),
        };
    }
    if (printOption?.copySelection === PrintOptionCopyType.FIXED) {
      const entry = printOption?.costPerCopy.find(
        item => item.quantity === quantity,
      );
      if (entry)
        return {
          SIMPLEX: Number(entry.cost.SIMPLEX),
          DUPLEX: Number(entry.cost.DUPLEX),
        };
    }
    return {
      SIMPLEX: 0,
      DUPLEX: 0,
    };
  };

  const onChangeCopies = (copies: number) => {
    if (!material?.printMode) return;
    const totalAmount =
      copies * getPriceByQuantity(copies)[material?.printMode];
    if (totalAmount)
      onChange?.({
        ...material,
        copies,
        totalAmount,
      });
  };

  const onChangePrintOption = (printMode: PrintMode) => {
    const { copies } = material;
    if (!copies) return;
    const totalAmount = copies * getPriceByQuantity(copies)[printMode];
    if (totalAmount) onChange?.({ ...material, totalAmount, printMode });
  };

  const isSingleMaterial =
    Object.keys(form.getFieldValue("materials")).length === 1 && material;

  const requiredCheckboxRule = (message: string): Rule[] => [
    {
      validator: (_, value) =>
        value ? Promise.resolve() : Promise.reject(new Error(message)),
    },
  ];
  const { printMode: printModeOptions } = printOption;
  return (
    <div className={styles.formContainer}>
      {isSingleMaterial && (
        <Typography.Title level={4} className={styles.title}>
          {material?.name}
        </Typography.Title>
      )}
      <div className={styles.divContainer}>
        <Form.Item
          name={["materials", materialId, "copies"]}
          label="Copies"
          required
          wrapperCol={{ span: 6 }}
          labelCol={{ span: 6 }}
        >
          <CopiesField
            printOption={printOption}
            value={material?.copies}
            onChange={onChangeCopies}
          />
        </Form.Item>
        <Form.Item
          name={["materials", materialId, "printMode"]}
          label="Print Mode"
          required
          labelCol={{ span: 6 }}
          tooltip={{
            title: (
              <>
                Choose <strong>Simplex</strong> option to print on one side of
                the paper.
                <br />
                Choose <strong>Duplex</strong> option to print on both sides of
                the paper.
              </>
            ),
            placement: "right",
          }}
        >
          <Select
            disabled={printModeOptions?.length === 1}
            defaultActiveFirstOption
            onChange={onChangePrintOption}
            options={printModeOptions?.map(printMode => ({
              label: capitalize(printMode),
              value: printMode,
            }))}
          />
        </Form.Item>
        <Form.Item labelCol={{ span: 6 }} label="Total Amount">
          <div className={styles.totalAmountContainer}>
            <Typography.Text strong className={styles.totalAmountText}>
              {`$${material?.totalAmount.toFixed(2)}`}
            </Typography.Text>
            <Typography.Text className={styles.totalAmountPages}>
              {` (${material?.copies ?? 0} pages total)`}
            </Typography.Text>
          </div>
        </Form.Item>
      </div>
      <PrintOptionRateTable printOption={printOption} />
      <PrintOptionProductionTimeCard
        productionTime={printOption.productionTime}
      />
      <div className={styles.confirmationContainer}>
        <Form.Item
          style={{ marginBottom: 0 }}
          required
          label="I confirm that:"
        />
        <Form.Item
          name={["materials", materialId, "confirm", "text"]}
          valuePropName="checked"
          rules={requiredCheckboxRule(
            "Please confirm the text is clear and spelled correctly.",
          )}
        >
          <Checkbox>The text is clear and spelled correctly.</Checkbox>
        </Form.Item>
        <Form.Item
          name={["materials", materialId, "confirm", "images"]}
          valuePropName="checked"
          rules={requiredCheckboxRule(
            "Please confirm the images are correctly placed.",
          )}
        >
          <Checkbox>
            The images are correctly placed; there are no blur or scale issues.
          </Checkbox>
        </Form.Item>
        <Form.Item
          name={["materials", materialId, "confirm", "review"]}
          valuePropName="checked"
          rules={requiredCheckboxRule(
            "Please confirm you have reviewed and approve this material.",
          )}
        >
          <Checkbox>I have reviewed and approve this material.</Checkbox>
        </Form.Item>
      </div>
    </div>
  );
};
